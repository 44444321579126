import FilterTabs from '@/components/molecules/FilterTabs';
import { FC, useState } from 'react';
import {
  ChatIcon,
  // EmailIcon,
  // SMSIcon,
  // MessengerIcon,
  // WhatsAppIcon,
} from '@/components/molecules/FilterTabs/icons';
// import Email from './email';
// import Messenger from './messenger';
// import SMS from './sms';
import Chat from './chat';
// import WhatsApp from './whatsapp';
import { User } from '@/types/user.types';

const ConversationsList: FC<{ data: User | null }> = ({ data }) => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabChange = (id: number) => {
    setActiveTab(id);
  };
  const options = [
    { id: 1, title: 'Chat', icon: <ChatIcon /> },
    // { id: 2, title: 'Email', icon: <EmailIcon />, disabled: true },
    // { id: 3, title: 'SMS', icon: <SMSIcon />, disabled: true },
    // { id: 4, title: 'Messenger', icon: <MessengerIcon />, disabled: true },
    // { id: 5, title: 'WhatsApp', icon: <WhatsAppIcon />, disabled: true },
  ];
  return (
    <div className='flex flex-col pt-4 h-screen'>
      <hr className='h-px hidden sm:block border-0 bg-[#ECEDF0] my-4'></hr>
      <div className='h-[37px] sm:flex flex-row w-full hidden justify-between'>
        <FilterTabs
          options={options}
          onChange={handleTabChange}
          activeTab={activeTab}
        />
      </div>
      <hr className='h-px hidden sm:block border-0 bg-[#ECEDF0] my-4'></hr>
      {activeTab == 1 && <Chat data={data} />}
      {/* {activeTab == 2 && <Email />} */}
      {/* {activeTab == 3 && <SMS />} */}
      {/* {activeTab == 4 && <Messenger />} */}
      {/* {activeTab == 5 && <WhatsApp />} */}
    </div>
  );
};
export default ConversationsList;
