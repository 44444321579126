import { useUpload } from '@/hooks/useUpload';
import { useWidgetConfig } from '@/hooks/useWidgetConfig';
import { WidgetConfigStore } from '@/state/WidgetConfigStore';
import { useEffect } from 'react';

export const useStyle = () => {
  const { uploading: sending, upload: send, response: answer, upload_progress: progress } = useUpload();
  const { uploading: sync, upload: transmit, response: reply, upload_progress: upload_percentage } = useUpload();
  const { publish } = useWidgetConfig();
  const handleHeaderColorInputChange = (e: any) => {
    WidgetConfigStore.updateHeaderBackgroundColor(e.target.value);
  };

  const handleHeaderTextColorInputChange = (e: any) => {
    WidgetConfigStore.updateHeaderTextColor(e.target.value);
  };

  const handleBorderColorInputChange = (e: any) => {
    WidgetConfigStore.updateBorderColor(e.target.value);
  };

  const handleButtonColorInputChange = (e: any) => {
    WidgetConfigStore.updateButtonColor(e.target.value);
  };

  const handlePublish = () => {
    publish();
  };

  function handleUpload(payload: any) {
    if (payload.target.files.length) {
      send(payload.target.files[0]);
    }
  }

  function handleUploadWidget(payload: any) {
    if (payload.target.files.length) {
      WidgetConfigStore.setIsUploadingWidgetLogo(true);
      transmit(payload.target.files[0]);
    }
  }

  useEffect(() => {
    if (answer) {
      if (!answer.error) {
        // dispatch update to image url here
        WidgetConfigStore.updateBrandLogoUrl(answer.url);
      }
    }
  }, [answer]);

  useEffect(() => {
    if (reply) {
      if (!reply.error) {
        WidgetConfigStore.updateWidgetButtonLogoUrl(reply.url);
      }
    }
  }, [reply]);

  return {
    handleHeaderColorInputChange,
    handleHeaderTextColorInputChange,
    handleBorderColorInputChange,
    handleButtonColorInputChange,
    handlePublish,
    sending,
    sync,
    answer,
    reply,
    progress,
    upload_percentage,
    handleUpload,
    handleUploadWidget,
  };
};
