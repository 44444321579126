import Logo from '@/components/atoms/Logo';
import { Button } from '@/components/ui/button';
import { notify } from '@/helpers';
import routeNames from '@/routes/routeNames';
import { FallbackProps, useErrorBoundary } from 'react-error-boundary';

export const ErrorFallback = (props: FallbackProps) => {
  const { resetBoundary } = useErrorBoundary();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    resetBoundary();
    notify('success', 'Information copied to clipboard');
  };

  return (
    <div>
      <div className='w-full p-3 px-5 bg-slate-50 flex flex-row justify-between'>
        <div className='flex flex-row cursor-pointer items-center'>
          <a
            className='flex items-center'
            href={routeNames.authentication.login}
          >
            <Logo size='md' />
            <p className='ml-2 text-lg font-semibold noselect'>Sparkhub</p>
          </a>
        </div>
        <p className='underline cursor-pointer text-primary-light'></p>
      </div>
      <div className='flex flex-col items-center justify-center h-screen space-y-4'>
        <div className='bg-slate-50 p-4 rounded-lg flex flex-col items-center justify-center h-[300px] w-[500px]'>
          <h1 className='text-2xl font-semibold'>Something went wrong</h1>
          <p className='p-2 text-center mb-3'>{props.error.message}</p>

          <Button
            onClick={() => copyToClipboard(props.error.stack || '')}
            className='text-white'
            variant='secondary'
          >
            Copy information for technical support
          </Button>
        </div>
      </div>
    </div>
  );
};
