// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import EmptyList from '@/components/atoms/EmptyList';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';
import { useEmailMarketing } from '@/hooks/useEmailMarketing';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { emailMarketingStore, Template } from '@/state/EmailMarketingStore';
import { replaceRouteParam } from '@/utils/routes';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Badge } from '@/components/ui/badge';
import {
  ChevronDown,
  Copy,
  Edit,
  Eye,
  MoreVertical,
  PlusCircle,
  Search,
  Trash,
  X,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { createColumnHelper } from '@tanstack/react-table';
import {
  DataTable,
  usePagination,
  useSorting,
} from '@/components/template/DataTable';
import { MainContainer } from '@/components/template/MainContainer';

const columnHelper = createColumnHelper<Template>();
const Templates = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isClone, setClone] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);
  const [templateToClone, setTemplateToClone] = useState<string | null>(null);
  const [templateToPreview, setTemplateToPreview] = useState<string | null>(
    null,
  );
  const [templateName, setTemplateName] = useState<string | null>(null);

  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const { getTableTemplates, deleteTemplate, cloneTemplate } =
    useEmailMarketing();
  const templates = emailMarketingStore?.templates;
  const INITIAL_PAGE_SIZE = 10;

  const [searchTerm, setSearchTerm] = useState('');

  const { onSortingChange, sorting } = useSorting();
  const { limit, page, pagination } = usePagination(INITIAL_PAGE_SIZE);

  const handleDeleteClick = (id: string) => {
    setTemplateToDelete(id);
    setIsOpen(true);
  };

  const handleCloneClick = (id: string) => {
    setTemplateToClone(id);
    setClone(true);
  };

  const handleConfirmDelete = () => {
    if (templateToDelete) {
      deleteTemplate(templateToDelete, {
        page: page + 1,
        limit: limit,
        search: searchTerm || null,
        type: selectedType || null,
        tags: selectedValues.length ? selectedValues : null,
      });
      setTemplateToDelete(null);
    }
    setIsOpen(false);
  };

  const handleConfirmClone = (id: string) => {
    const template = templates?.templates?.find(
      (temp: any) => temp?._id === id,
    );
    if (template) {
      cloneTemplate(
        {
          name: `${template.name} - Clone`,
          type: template.type,
          html: template.html,
          json: template.json,
          status: template.status,
          tags: template.tags,
        },
        {
          page: page + 1,
          limit: limit,
          search: searchTerm || null,
          type: selectedType || null,
          tags: selectedValues.length ? selectedValues : null,
        },
      );
      setTemplateToClone(null);
    }
    setClone(false);
  };

  const preview = (id: string) => {
    const template = templates?.templates?.find(
      (temp: any) => temp?._id === id,
    );
    if (template) {
      setTemplateToPreview(template.html);
      setTemplateName(template?.name);
      setIsPreview(true);
    }
  };

  const filteredTemplates = templates?.templates?.filter((template: any) => {
    const matchesSearch =
      template?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template?.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template?.status?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesType = selectedType ? template.type === selectedType : true;

    const matchesTags =
      selectedValues.length > 0
        ? selectedValues.some(tag => template.tags.includes(tag))
        : true;

    return matchesSearch && matchesType && matchesTags;
  });

  const totalTemplates = templates?.count || 0;
  const options = [
    { value: 'autumn', label: 'Autumn' },
    { value: 'spring', label: 'Spring' },
    { value: 'summer', label: 'Summer' },
    { value: 'winter', label: 'Winter' },
    { value: 'anniversary', label: 'Anniversary' },
    { value: 'backtoschool', label: 'Back to School' },
    { value: 'birthday', label: 'Birthday' },
    { value: 'blackfriday', label: 'Black Friday' },
    { value: 'christmas', label: 'Christmas' },
    { value: 'cybermonday', label: 'Cyber Monday' },
    { value: 'easter', label: 'Easter' },
  ];

  const toggleOption = (value: string) => {
    setSelectedValues(prev =>
      prev.includes(value)
        ? prev.filter(item => item !== value)
        : [...prev, value],
    );
  };

  useEffect(() => {
    getTableTemplates({
      page: page + 1,
      limit: limit,
      search: searchTerm || null,
      type: selectedType || null,
      tags: selectedValues.length ? selectedValues : null,
    });
  }, [searchTerm, selectedType, selectedValues, page, limit]);

  const columns = useMemo(() => [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>Name</span>,
      cell: info => (
        <button
          onClick={() => {
            navigate(
              replaceRouteParam(
                `${routeNames.dashboard.emailTemplates}/${info.row.original?._id}`,
                'workspaceId',
                AuthStore.user_workspace_info.active_workspace.workspace._id,
              ),
            );
            emailMarketingStore.setTemplateId('');
          }}
          className='font-medium hover:underline text-left overflow-hidden whitespace-no-wrap truncate min-w-[300px]'
        >
          {info.getValue()}
        </button>
      ),
    }),
    columnHelper.accessor('type', {
      id: 'type',
      header: () => <span>Type</span>,
      cell: info => <p>{info.getValue() || '-'}</p>,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => <span>Status</span>,
      cell: info => (
        <Badge variant={info.getValue() === 'Draft' ? 'default' : 'secondary'}>
          {info.getValue() || '-'}
        </Badge>
      ),
    }),
    columnHelper.accessor('createdBy', {
      id: 'createdBy',
      header: () => <span>Created By</span>,
      cell: info => <p>{info.row.original?.createdBy?.user_name || '-'}</p>,
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => <span className='text-right'>Actions</span>,
      cell: info => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline' size='icon' className='h-8 w-8'>
              <MoreVertical className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuItem
              className='text-md'
              onClick={() => preview(info.row.original._id)}
            >
              <Eye className='mr-2 h-4 w-4' /> Preview
            </DropdownMenuItem>
            <DropdownMenuItem
              className='text-md'
              onClick={() =>
                navigate(
                  replaceRouteParam(
                    `${routeNames.dashboard.emailTemplates}/${info.row.original?._id}`,
                    'workspaceId',
                    AuthStore.user_workspace_info.active_workspace.workspace
                      ._id,
                  ),
                )
              }
            >
              <Edit className='mr-2 h-4 w-4' /> Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className='text-md'
              onClick={() => handleCloneClick(info.row.original._id)}
            >
              <Copy className='mr-2 h-4 w-4' /> Clone
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => handleDeleteClick(info.row.original._id)}
              className='text-red-500 text-md'
            >
              <Trash className='mr-2 h-4 w-4 text-red-500' /> Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    }),
  ]);

  return (
    <MainContainer>
      <div className='px-6 pt-6 flex justify-between items-center mb-2'>
        <h1 className='text-2xl font-bold'>Templates</h1>
        <Button
          onClick={() => {
            navigate(
              replaceRouteParam(
                routeNames.dashboard.emailTemplates,
                'workspaceId',
                AuthStore.user_workspace_info.active_workspace.workspace._id,
              ),
            );
            emailMarketingStore.setTemplateId('');
          }}
          className='bg-secondary hover:bg-secondary/90 text-white text-md'
        >
          <PlusCircle className='mr-2 h-6 w-6' />
          New Template
        </Button>
      </div>

      <div className='flex gap-4 px-6 pt-4'>
        <div className='relative flex-1'>
          <Search className='absolute left-2 top-2.5 h-4 w-4 text-muted-foreground' />
          <Input
            placeholder='Search templates...'
            className='pl-8 pr-4 py-2 w-full focus-visible:outline-none focus-visible:ring-0
               focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <Select
          value={selectedType}
          onValueChange={value => setSelectedType(value)}
        >
          <SelectTrigger
            className='w-[245px] focus:ring-0 focus:ring-offset-0
                           focus-within:border focus-within:border-secondary'
          >
            <SelectValue placeholder='Template type' />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value='basic layout'>Basic layout</SelectItem>
            <SelectItem value='deals'>Deals</SelectItem>
            <SelectItem value='confirmation'>Confirmation</SelectItem>
            <SelectItem value='outreach'>Outreach</SelectItem>
            <SelectItem value='reminders'>Reminders</SelectItem>
            <SelectItem value='sales'>Sales</SelectItem>
          </SelectContent>
        </Select>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant='outline'
              className='w-[245px] flex items-center justify-between  capitalize'
            >
              <span className='truncate overflow-hidden whitespace-nowrap'>
                {selectedValues.length > 0
                  ? selectedValues.join(', ')
                  : 'Select Seasons & Holidays'}
              </span>
              <ChevronDown className='h-4 w-4 text-black/50 flex-shrink-0 ml-2' />
            </Button>
          </PopoverTrigger>
          <PopoverContent className='w-[245px] p-2'>
            <div className='flex flex-col gap-2'>
              {options.map(option => (
                <label
                  key={option.value}
                  className='flex items-center space-x-2 cursor-pointer'
                >
                  <Checkbox
                    checked={selectedValues.includes(option.value)}
                    onCheckedChange={() => toggleOption(option.value)}
                  />
                  <span>{option.label}</span>
                </label>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      </div>
      {selectedType ? (
        <div className='flex items-center gap-2 mb-6 px-6 pt-4'>
          <Badge variant='outline' className='flex items-center gap-1 text-md'>
            {selectedType}
          </Badge>
          <Button
            variant='link'
            className='text-blue h-auto p-0 text-md'
            onClick={() => setSelectedType('')}
          >
            Clear
          </Button>
        </div>
      ) : (
        <div className='mb-12' />
      )}

      <DataTable
        pagination={pagination}
        rowCount={totalTemplates}
        sorting={sorting}
        pageSize={INITIAL_PAGE_SIZE}
        loading={emailMarketingStore.loading}
        onSortingChange={onSortingChange}
        noDataComponent={
          <EmptyList
            title='There are no templates'
            subTitle='Add templates to see them here'
            height='300px'
          />
        }
        columns={columns as any}
        data={filteredTemplates || []}
        headerClassName='bg-gray-100 text-[#161518] font-medium text-md first:w-9 even:w-auto'
        showPagination
      />

      <ConfirmationModal
        title='Delete Template'
        content='Are you sure you want to delete this template?'
        show={isOpen}
        setShow={setIsOpen}
        confirmText='Done'
        onConfirm={handleConfirmDelete}
      />
      <ConfirmationModal
        title='Clone Template'
        content='Are you sure you want to clone this template?'
        show={isClone}
        setShow={setClone}
        confirmText='Done'
        onConfirm={() => handleConfirmClone(templateToClone as string)}
      />
      <Dialog open={isPreview} onOpenChange={setIsPreview}>
        <DialogContent className='bg-white border-none p-8 max-w-[1000px] max-h-[90vh] position-fixed overflow-y-auto [&>button]:hidden'>
          <DialogHeader className='flex flex-row justify-between items-center'>
            <DialogTitle className='text-2xl font-semibold text-secondary'>
              {templateName}
            </DialogTitle>
            <DialogClose asChild>
              <Button variant='ghost' size='icon'>
                <X className='h-6 w-6' />
              </Button>
            </DialogClose>
          </DialogHeader>
          <div
            dangerouslySetInnerHTML={{
              __html: templateToPreview as string,
            }}
          />
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};
export default observer(Templates);
