import Button from '@/components/atoms/Button';
import TextInput from '@/components/atoms/Input';
import { RegisterWithInvitePayload } from '@/hooks/useAuth';
import routeNames from '@/routes/routeNames';
import { Icon } from '@iconify/react';

import { AuthStore } from '@/state/AuthenticationStore';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

const signUpWithInviteSchema = Yup.object().shape({
  email: Yup.string().trim().email('Invalid email').required('Email required'),
  user_name: Yup.string().trim().required('Full name required'),
  password: Yup.string()
    .trim()
    .min(6, 'Password too short')
    .required('Password required'),
  workspaceId: Yup.string().trim().required('Workspace ID required'),
});

type SignUpWithInviteFormProps = {
  handleSignUp: (value: RegisterWithInvitePayload) => void;
};

const SignUpWithInviteForm: FC<SignUpWithInviteFormProps> = ({
  handleSignUp,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.state?.from?.search);

  const loadingState = () => {
    AuthStore.loading = true;
  };

  return (
    <Formik
      initialValues={{
        user_name: '',
        email: searchParams.get('email') || '',
        password: '',
        workspaceId: searchParams.get('space') || '',
        isAccountValid: searchParams.get('valid') || '',
      }}
      validationSchema={signUpWithInviteSchema}
      onSubmit={handleSignUp}
    >
      {({
        errors,
        touched,
        values,
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        dirty,
      }) => (
        <form className='space-y-3' onSubmit={handleSubmit}>
          {AuthStore.error && (
            <small className='text-red-500'>{AuthStore.error}</small>
          )}
          <TextInput
            onChange={handleChange('user_name')}
            onBlur={handleBlur('user_name')}
            disabled={AuthStore.loading}
            name={'user_name'}
            label={'Full name'}
            error={
              errors.user_name && touched.user_name ? errors.user_name : ''
            }
            placeholder={'First and last name'}
          />

          <TextInput
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            disabled={!!values.email || AuthStore.loading}
            label={'Email'}
            name={'email'}
            type='email'
            placeholder='Email'
            value={values.email}
            error={errors.email && touched.email ? errors.email : ''}
          />

          <input
            onChange={handleChange('workspaceId')}
            onBlur={handleBlur('workspaceId')}
            disabled={AuthStore.loading}
            name={'workspaceId'}
            type='hidden'
            placeholder='workspaceId'
            value={values.workspaceId}
          />

          <input
            onChange={handleChange('isAccountValid')}
            onBlur={handleBlur('isAccountValid')}
            disabled={AuthStore.loading}
            name={'isAccountValid'}
            type='hidden'
            value={values.isAccountValid}
          />

          <TextInput
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            disabled={AuthStore.loading}
            label={'Password'}
            type={showPassword ? 'text' : 'password'}
            name={'password'}
            placeholder={'Password (at least 8 characters)'}
            error={errors.password && touched.password ? errors.password : ''}
            rightIcon={
              <button
                type='button'
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            }
          />

          <div className='flex items-center justify-center'>
            <Button
              disabled={!(isValid && dirty) || AuthStore.loading}
              onClick={loadingState}
              loading={AuthStore.loading}
              loadingText={'Creating account...'}
              text={'Create account'}
              className='mt-4 w-full group relative hover:bg-primary-medium'
              size='sm'
              LeftIcon={
                <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                  <Icon
                    icon='mdi:lock'
                    className='h-5 w-5 text-primary-medium text-opacity-40 group-hover:text-white'
                    aria-hidden='true'
                  />
                </span>
              }
            />
          </div>

          <p className='pt-2 pb-6 text-md text-gray-400 text-center'>
            Already using SparkHub?{' '}
            <Link
              to={routeNames.authentication.login}
              className='font-medium text-primary-light hover:text-primary-medium'
            >
              Log in
            </Link>
          </p>
        </form>
      )}
    </Formik>
  );
};

export default observer(SignUpWithInviteForm);
