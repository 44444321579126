/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { AuthStore } from '@/state/AuthenticationStore';
import { useState } from 'react';
import '@/libs/socket.io-stream';
export const useUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [response, setResponse] = useState<any>(null);
  const [upload_progress, set_upload_progress] = useState(0);
  const upload = (file: any) => {
    setUploading(true);
    const stream = ss.createStream();
    let blobStream = ss.createBlobReadStream(file);
    let size = 0;
    ss(AuthStore.socket).emit(
      'file',
      stream,
      { size: file.size, name: file.name },
      (data: any) => {
        stream.destroy();
        blobStream = undefined;
        setResponse(data);
        setUploading(false);
      },
    );

    blobStream.on('data', function (chunk: any) {
      size += chunk.length;
      set_upload_progress(Math.floor((size / file.size) * 100));
    });
    blobStream.pipe(stream);
  };

  return {
    response,
    uploading,
    upload_progress,
    upload,
  };
};
