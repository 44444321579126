import { ContactStats } from '@/types/contact-stats.types';
import { Orders } from '@/types/orders.types';
import { User } from '@/types/user.types';
import { makeAutoObservable } from 'mobx';

class ContactStore {
  constructor() {
    makeAutoObservable(this);
  }

  public contacts: User[] = [];
  public orders: Orders[] = [];
  public contactStats: ContactStats[] = [];
  public recentViewContacts: User[] = [];
  public myContacts: User[] = [];
  public contact: User | null = null;
  public selectedContact: User | null = null;
  public error: string | null = null;
  public fetchingContact = false;
  public uploadingContact = false;
  public creatingContact = false;
  public updatingContact = false;
  public fetchUser = false;
  public fetchingOrders = false;
  public totalCount = 0;
  public campaignSenderList: string[] = [];
  public estimatedRecipientsCount = 0;
  public fetchingEstimatedRecipients = false;

  public setContacts(contacts: User[]) {
    this.contacts = contacts;
  }

  public setFetchingContact(loading: boolean) {
    this.fetchingContact = loading;
  }

  public setFetchingOrders(loading: boolean) {
    this.fetchingOrders = loading;
  }

  public setOrders(payload: Orders[]) {
    this.orders = payload;
  }

  public setCreating(creating: boolean) {
    this.creatingContact = creating;
  }

  public setFetchingUser(loading: boolean) {
    this.fetchUser = loading;
  }
  public setUpdatingContact(loading: boolean) {
    this.updatingContact = loading;
  }
  public setContact(contact: User | null) {
    this.contact = contact;
  }

  public setError(error: string | null) {
    this.error = error;
  }

  public setRecentViewContacts(contacts: User[]) {
    this.recentViewContacts = contacts;
  }

  public setMyContacts(contacts: User[]) {
    this.myContacts = contacts;
  }
  public setContactStats(stats: ContactStats[]) {
    this.contactStats = stats;
  }

  public setTotalCount(totalCount: number) {
    this.totalCount = totalCount;
  }

  public setUploadingContact(loading: boolean) {
    this.uploadingContact = loading;
  }

  public setFetchingEstimatedRecipients(value: boolean) {
    this.fetchingEstimatedRecipients = value;
  }

  public setEstimatedRecipientsCount(value: number) {
    this.estimatedRecipientsCount = value;
  }

  public setCampaignSenderList(value: string[]) {
    this.campaignSenderList = value;
  }
}

export const contactStore = new ContactStore();
