/* eslint-disable @typescript-eslint/no-explicit-any */
import Icon from '@/assets/Icons';
import Icons from '@/assets/Icons/icons.json';
import Button from '@/components/atoms/Button';
import Checkbox from '@/components/atoms/Checkbox';
import EmptyList from '@/components/atoms/EmptyList';
import Modal from '@/components/atoms/modal';
import ConfirmationModal from '@/components/atoms/modal/ConfirmationModal';
import Search from '@/components/atoms/Search';
import Text from '@/components/atoms/Text';
import ToolTip from '@/components/atoms/Tooltip';
import {
  DataTable,
  usePagination,
  useSorting,
} from '@/components/template/DataTable';
import { MainContainer } from '@/components/template/MainContainer';
import { notify } from '@/helpers/index';
import { useContact } from '@/hooks/useContact';
import { useCustomField } from '@/hooks/useCustomField';
import { useDebounceValue } from '@/hooks/useDebounce';
import { useList } from '@/hooks/useList';
import { useSMS } from '@/hooks/useSMS';
import { ContactType } from '@/pages/Contacts';
import ListActions from '@/pages/Contacts/components/ListActions';
import { AuthStore } from '@/state/AuthenticationStore';
import { contactStore } from '@/state/ContactStore';
import { customFieldStore } from '@/state/CustomFieldStore';
import { listStore } from '@/state/ListStore';
import { CONVERSATION_CHANNEL } from '@/types/conversation.types';
import { User } from '@/types/user.types';
import {
  AccessorKeyColumnDef,
  createColumnHelper,
  RowSelectionState,
  VisibilityState,
} from '@tanstack/react-table';
import { observer } from 'mobx-react-lite';
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { DropdownOption, DropdownPicker } from '../DropdownPicker';
import NewContact from '../NewContact';
import ProfileTab from '../ProfileTab';
import { Filter, FilterCondition, filterStoreKey } from './filter';
import { TableListFilter } from './menu';

const columnHelper = createColumnHelper<User>();
export const localKey = 'contact:selected-fields';

const Table: FC<{ title?: string; contactType: ContactType }> = ({
  title,
  contactType,
}) => {
  const {
    open,
    openImport,
    createContact,
    importContacts,
    closeModal,
    openModal,
    openModalImport,
    closeModalImport,
    getContacts,
    handleViewContact,
    handleSaveToMyContactList,
    handleSaveToList,
    deleteContactFromList,
  } = useContact();
  const {
    openAddList,
    open: isOpen,
    setOpen: setIsOpen,
    closeAddListModal,
    openAddListModal,
    setOpenAddList,
    deleteList,
    updateList,
  } = useList();
  const { send } = useSMS();
  const { getCustomFields, customFields } = useCustomField();
  const { pathname } = useLocation();
  const isOnMyContact = pathname.includes('my-contacts');

  const contactColumns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor('_id', {
        id: 'select',
        meta: {
          label: undefined,
        },
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected().toString(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected().toString(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('user_name', {
        id: 'user_name',
        meta: {
          label: 'Name',
          customField: false,
        },
        cell: info => (
          <div className='cursor-pointer py-1 w-full'>
            <ProfileTab
              name={info.getValue() ?? '-'}
              imageURL={info.row.original?.profile_picture_url as string}
              onClick={() =>
                handleViewContact(info.row.original?._id, isOnMyContact)
              }
            />
          </div>
        ),
        header: () => <span>Name</span>,
      }),
      columnHelper.accessor('phone_number', {
        id: 'phone_number',
        meta: {
          label: 'Phone',
          customField: false,
        },
        cell: info => <p className='truncate'>{info.getValue() ?? '-'}</p>,
        header: () => <span>Phone</span>,
      }),
      columnHelper.accessor('email', {
        id: 'email',
        meta: {
          label: 'Email',
          customField: false,
        },
        cell: info => <p className='truncate'>{info.getValue() ?? '-'}</p>,
        header: () => <span>Email</span>,
      }),

      columnHelper.accessor('company_name', {
        id: 'company_name',
        meta: {
          label: 'Company',
          customField: false,
        },
        cell: info => <p className='truncate'>{info.getValue() ?? '-'}</p>,
        header: () => <span>Company</span>,
      }),

      columnHelper.accessor('city', {
        id: 'city',
        meta: {
          label: 'City',
          customField: false,
        },
        cell: info => <p className='truncate'>{info.getValue() ?? '-'}</p>,
        header: () => <span>City</span>,
      }),

      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        meta: {
          label: 'Created date',
          customField: false,
        },
        cell: info => (
          <p className='truncate'>
            {' '}
            {new Date(info.getValue()).toDateString() || '-'}
          </p>
        ),
        header: () => <span>Created date</span>,
      }),

      columnHelper.accessor('last_seen', {
        id: 'last_seen',
        meta: {
          label: 'Last seen',
          customField: false,
        },
        cell: info => (
          <p className='truncate'>
            {info.getValue() ? new Date(info.getValue()).toDateString() : '-'}
          </p>
        ),
        header: () => <span>Last seen</span>,
      }),
    ];

    const generatedColumns: AccessorKeyColumnDef<User, string>[] = [];
    customFields?.forEach(field => {
      generatedColumns.push(
        columnHelper.accessor(field.field as keyof User, {
          header: field.field,
          meta: {
            label: field.field,
            customField: true,
          },
          cell: info => {
            const value = info.getValue();
            return value ? value : '-';
          },
        }),
      );
    });

    return [...baseColumns, ...generatedColumns];
  }, [customFields?.length]);

  const [searchQuery, setSearchQuery] = useState('');
  const debounceSearchValue = useDebounceValue(searchQuery, 500);
  const [value, setValue] = useState('');
  const [selectedContacts, setSelectedContacts] = useState<RowSelectionState>(
    {},
  );
  const [openSMS, setOpenSMS] = useState(false);
  const [openWhatsapp, setOpenWhatsapp] = useState(false);
  const [selectedList, setSelectedList] = useState<DropdownOption>();
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<DropdownOption>();
  const { listId } = useParams();
  const [isListID, setIsListID] = useState<string>('');
  const [defaultListID, setDefaultListID] = useState<string>('');
  const [clearRows, setClearRows] = useState(false);
  const [storeSelectedContacts, setStoreSelectedContacts] =
    useState<RowSelectionState>({});
  const [openDeleteContactFromList, setOpenDeleteContactFromList] =
    useState(false);
  const [contactColumnVisibility, setContactColumnVisibility] =
    useState<VisibilityState>({});

  const isTextareaEmpty = value.trim().length === 0;
  const contactIds = Object.keys(selectedContacts)?.map(
    index => contactStore.contacts[Number(index)]?._id,
  );
  const { pagination, limit, page } = usePagination();
  const { sorting, onSortingChange } = useSorting();
  const isDeleteListDisabled =
    (listStore.lists?.find(list => listId === list._id)?.total_contacts ?? 0) >
    0;

  const showDeleteButton = listId && location.pathname.includes('list');

  const isSMSEnabled =
    !!AuthStore.user_workspace_info?.active_workspace?.workspace?.integrations?.find(
      integration =>
        integration.name === 'Twilio' ||
        integration.name === 'Vonage' ||
        integration.name === 'Telnyx',
    ) || false;

  // const isTwilioEnabled =
  //   !!AuthStore.user_workspace_info?.active_workspace?.workspace?.integrations?.find(
  //     integration => integration.name === 'Twilio',
  //   ) || false;

  useMemo(() => {
    const fromLs = localStorage.getItem(localKey);
    const oldColumnVisibility = fromLs
      ? (JSON.parse(fromLs) as unknown as VisibilityState)
      : {};

    const initialColumnVisibility: VisibilityState = {};

    contactColumns.forEach(column => {
      initialColumnVisibility[column.accessorKey] = !column.meta?.customField;
    });

    setContactColumnVisibility(
      Object.keys(oldColumnVisibility).length
        ? oldColumnVisibility
        : initialColumnVisibility,
    );
  }, [contactColumns]);

  const setListName = (name: string) => {
    const urlList = new URL(window.location.href);
    urlList.searchParams.set('list', name);
    window.history.pushState(null, '', urlList.toString());
  };

  const listItems = [
    { label: 'Selected contacts', value: 'selected_contacts' },
    { label: 'All Contacts', value: 'all' },
    { label: 'My Contacts', value: 'my_contacts' },

    ...(listStore.lists?.map(list => ({
      label: list.name,
      value: list._id,
    })) || []),
  ];

  const getListName = () => {
    const splittedUrl = window.location.pathname.split('/');
    const listName = splittedUrl[splittedUrl.length - 1];
    const listUrl = new URL(window.location.href);
    const favListName = listUrl.searchParams.get('list');

    switch (listName) {
      case 'recently-viewed':
        return 'Recently Viewed';
      case 'my-contacts':
        return 'My Contacts';
      default:
        return favListName || 'All Contacts';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const handleOptionClick = (option: DropdownOption) => {
    setSelectedList(option);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!openSMS) {
      setStoreSelectedContacts(selectedContacts);
    }
  }, [selectedContacts, openSMS]);

  useEffect(() => {
    if (openSMS) {
      const allSelected = contactStore.contacts.reduce(
        (acc, contact, index) => {
          acc[index] = true;
          return acc;
        },
        {} as RowSelectionState,
      );

      setSelectedContacts(allSelected);
    }
  }, [contactStore.contacts]);

  const handleContactList = async (option: DropdownOption) => {
    if (option.value === 'all') {
      setDefaultListID(option.value);
      setIsListID('');
    } else if (option.value == 'selected_contacts') {
      setSelectedContacts(storeSelectedContacts);
      setIsListID('');
    } else if (option.value == 'my_contacts') {
      setDefaultListID(option.value);
      setIsListID('');
    } else if (option.value == 'recently-viewed') {
      setDefaultListID(option.value);
      setIsListID('');
    } else {
      setIsListID(option.value);
    }
    setSelectedContacts({});
    setSelectedDropdownOption(option);
    setIsOpen(false);
  };

  const handleSend = () => {
    const channel = openSMS
      ? CONVERSATION_CHANNEL.SMS
      : CONVERSATION_CHANNEL.WHATSAPP;

    const payload = {
      contact_id_list: contactIds,
      list_id: isListID,
      default_lists: defaultListID,
      text: value,
      conversation_channel: channel,
    };

    send(payload, channel);
    closeSMSWhatsappModal();
  };

  const closeSMSWhatsappModal = () => {
    openSMS ? setOpenSMS(false) : openWhatsapp ? setOpenWhatsapp(false) : null;
  };
  const openSMSModal = () => {
    setOpenSMS(true);
  };

  const handleOmitField = (fieldId: string) => {
    const data: VisibilityState = {
      ...contactColumnVisibility,
      [fieldId]: !contactColumnVisibility[fieldId],
    };
    setContactColumnVisibility(data);

    localStorage.setItem(localKey, JSON.stringify(data));
  };

  const handleSearch = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setSearchQuery(target.value);
  };

  const handleClearRows = () => {
    setClearRows(!clearRows);
  };

  const cancelOnSelectedRowsChange = () => {
    setSelectedContacts({});
    handleClearRows();
    closeAddListModal();
  };

  const listOptions = useMemo(
    () => [
      { label: 'My Contacts', value: 'my_contacts' },
      ...(listStore.lists?.map(list => ({
        label: list.name,
        value: list._id,
      })) || []),
    ],
    [listStore.lists],
  );

  const validateIfSelectedContactsHasPhone = () => {
    const selectedContacts = contactIds?.map(contactId =>
      contactStore.contacts.find(contact => contact._id === contactId),
    );

    const hasPhone = selectedContacts?.every(contact => contact?.phone_number);

    if (!hasPhone) {
      notify('error', 'Some contacts do not have a phone number');
    }

    return hasPhone;
  };

  useLayoutEffect(() => {
    const queryParams = listId
      ? `?list=${listId}`
      : `?contactType=${contactType}`;

    const search = debounceSearchValue.trim()
      ? `&search=${debounceSearchValue}`
      : '';
    const storedFilters = localStorage.getItem(filterStoreKey);
    const newValues = (
      typeof storedFilters === 'string' ? storedFilters : []
    ) as FilterCondition[];

    if (customFieldStore.customFields.length === 0) {
      getCustomFields();
    }
    getContacts(
      `${queryParams}${search}&limit=${limit}&page=${
        page + 1
      }&filters=${newValues}`,
    );
    return () => {
      setSelectedContacts({});
    };
  }, [
    listId,
    contactType,
    limit,
    page,
    listStore.deletingContactList,
    debounceSearchValue,
  ]);

  useMemo(() => {
    const defaultSelection =
      listItems.find(item => item.value === 'selected_contacts') ||
      listItems[0];
    setSelectedDropdownOption(defaultSelection);
  }, []);

  useMemo(() => {
    setSelectedList({
      label: listStore.lists[0]?.name || 'My Contacts',
      value: listStore.lists[0]?._id,
    });
  }, [listStore.lists.length]);

  useEffect(() => {
    const queryParams = listId
      ? `?list=${listId}`
      : `?contactType=${contactType}`;

    const search = debounceSearchValue.trim()
      ? `&search=${debounceSearchValue}`
      : '';

    window.addEventListener('storage', e => {
      getCustomFields();
      const newValues = (e.newValue ? e.newValue : []) as FilterCondition[];
      getContacts(
        `${queryParams}${search}&limit=${limit}&page=${
          page + 1
        }&filters=${newValues}`,
      );
    });
  }, [listId, contactType, limit, page, debounceSearchValue]);

  // const Tab = () => {
  //   return (
  //     <div className='flex  bg-gray-100 p-[2px] rounded-md h-[38px] text-[12px]'>
  //       <div
  //         onClick={() => setTab(0)}
  //         className={`${
  //           tab === 0
  //             ? 'bg-white shadow border border-black border-opacity-20 '
  //             : ''
  //         } cursor-pointer flex items-center justify-center text-center px-5 py-1 rounded-md w-[100%]`}
  //       >
  //         Table View
  //       </div>
  //       {/* <div
  //         onClick={() => setTab(1)}
  //         className={`${
  //           tab === 1
  //             ? 'bg-white shadow border border-black border-opacity-20 '
  //             : ''
  //         } cursor-pointer flex items-center justify-center text-center px-5 py-1 rounded-md w-[100%]`}
  //       >
  //         Board View
  //       </div> */}
  //     </div>
  //   );
  // };

  return (
    <MainContainer>
      <div className='py-10 px-6 bg-white rounded-t-[16px] flex justify-between gap-5 items-center z-[9] border border-b-0 border-border'>
        <div className='flex-1 flex items-center justify-between gap-5 '>
          <Text
            size='lg'
            className='font-[500] md:hidden lg:block text-ellipsis min-w-[120px] max-w-[200px] overflow-hidden text-nowrap'
            title={getListName()}
          >
            {getListName()}
          </Text>
          <Search
            placeholder='Search Contacts'
            value={searchQuery}
            onChange={handleSearch}
            transparent
            showCommand={false}
          />
        </div>
        <div className='flex items-center justify-between'>
          <div className='flex items-center justify-between'>
            <TableListFilter
              columns={contactColumns}
              columnVisibility={contactColumnVisibility}
              handleOmitField={handleOmitField}
            />

            <div className='z-[9999]'>
              <Filter />
            </div>

            <ListActions
              isDisabled={isDeleteListDisabled}
              handleDelete={() => deleteList(listId as string)}
              handleEdit={({ name }) => {
                updateList(listId as string, name);
                setListName(name);
              }}
            />
          </div>

          <NewContact
            contactType={contactType}
            open={open}
            openImport={openImport}
            handleCreateContact={createContact}
            handleImportContact={importContacts}
            openModal={openModal}
            closeModal={closeModal}
            openModalImport={openModalImport}
            closeModalImport={closeModalImport}
          />
        </div>
      </div>
      <DataTable
        rowCount={contactStore.totalCount}
        columns={contactColumns}
        pagination={pagination}
        loading={contactStore.fetchingContact || listStore.fetchingList}
        data={contactStore.contacts}
        headerClassName='bg-gray-100 text-[#161518] font-medium text-md first:w-9 even:w-auto last:w-auto'
        showPagination={contactStore.contacts.length > 0}
        noDataComponent={
          <EmptyList
            listName={title || ''}
            title='There are no contacts in'
            subTitle='Add contacts to see them in '
            height='65vh'
          />
        }
        onRowSelectionChange={setSelectedContacts}
        onColumnVisibilityChange={setContactColumnVisibility}
        rowSelection={selectedContacts}
        columnVisibility={contactColumnVisibility}
        sorting={sorting}
        onSortingChange={onSortingChange}
      />

      <div className='flex items-center justify-center mr-28'>
        {Object.keys(selectedContacts)?.length > 0 ? (
          <div className='absolute z-50 bottom-[90px] p-3 bg-white bg-opacity-30 backdrop-blur-[56px] rounded border border-border gap-4 flex'>
            <div className='justify-start items-center gap-2 flex'>
              <div className='h-5 px-2 flex flex-col text-center justify-center  bg-secondary rounded'>
                <span className='text-white text-sm font-medium leading-tight'>
                  {Object.keys(selectedContacts).length}
                </span>
              </div>
              <Text size='sm'>Selected</Text>
            </div>
            {showDeleteButton && (
              <Button
                text={
                  'Delete contact' +
                  (Object.keys(selectedContacts)?.length > 1 ? 's' : '')
                }
                variant='danger'
                onClick={() => setOpenDeleteContactFromList(true)}
              />
            )}
            <Button
              text='Save to list'
              variant='outline'
              onClick={() => {
                openAddListModal();
              }}
            />
            <div className='w-px h-[27px] bg-neutral-200' />
            <Button
              text='Send SMS'
              variant='outline'
              disabled={!isSMSEnabled}
              onClick={() => {
                validateIfSelectedContactsHasPhone();
                openSMSModal();
              }}
            />

            {/* <Button
              text='Send WhatsApp'
              variant='outline'
              disabled={!isTwilioEnabled}
              onClick={() => {
                if (!validateIfSelectedContactsHasPhone()) {
                  return;
                }
                setOpenWhatsapp(true);
              }}
            /> */}
            <Button
              text='Cancel'
              variant='outline'
              onClick={cancelOnSelectedRowsChange}
            />
          </div>
        ) : null}
      </div>

      {/* SEND SMS MODAL */}
      <Modal
        show={openSMS || openWhatsapp}
        openModal={() => setOpenSMS || setOpenWhatsapp}
        closeModal={closeSMSWhatsappModal}
        title={openSMS ? 'Send SMS' : openWhatsapp ? 'Send WhatsApp' : ''}
        className='z-[10000] inline-block py-6 my-8 w-[440px]
             text-left align-center transition-all transform bg-white shadow-xl rounded-md'
      >
        <div className='px-4'>
          <Text size='sm' color='text-gray-400 mt-6'>
            Message
          </Text>
          <textarea
            className='my-2 w-full outline-none text-md resize-none border border-border focus-within:border-secondary rounded-md p-2'
            placeholder='Write a message...'
            rows={3}
            value={value}
            onChange={handleChange}
          />
          <div className='mb-4'>
            <Text size='sm' color='text-gray-400'>
              Recipients
            </Text>
            <DropdownPicker
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selected={selectedDropdownOption?.label}
              onSelect={handleContactList}
              options={listItems}
            />
          </div>
          <div className='flex justify-between items-center mt-1'>
            <Button
              size='sm'
              text='Cancel'
              onClick={() => {
                openSMS ? setOpenSMS(false) : setOpenWhatsapp(false);
              }}
              variant='outline'
            />
            <div className='flex space-x-2 -z-1'>
              <ToolTip title='Coming soon'>
                <Icon icon={Icons.mic} color='#7E8B99' size={25} />
              </ToolTip>

              <ToolTip title='Coming soon'>
                <Icon icon={Icons.emoji} color='#7E8B99' size={25} />
              </ToolTip>

              <ToolTip title='Coming soon'>
                <Icon icon={Icons.attachment} color='#7E8B99' size={25} />
              </ToolTip>
              <ToolTip title='Coming soon'>
                <div className='h-[32px] px-[14px] py-[8px] border border-border rounded-[4px] flex items-center justify-center cursor-pointer'>
                  <Icon icon={Icons.spark} color='#7E8B99' size={25} />
                </div>
              </ToolTip>

              <Button
                type='submit'
                text='Send'
                size='sm'
                className='bg-secondary'
                disabled={isTextareaEmpty}
                onClick={handleSend}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* ADD TO LIST MODAL */}

      <Modal
        show={openAddList}
        openModal={() => setOpenAddList}
        closeModal={closeAddListModal}
        title='Save to list'
        className='z-[10000] inline-block py-6 my-8 w-[100%] max-w-[440px] text-left align-center  transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <div className='px-4'>
          <Text size='sm' color='text-gray-400 mt-6'>
            Selected list
          </Text>
          <DropdownPicker
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selected={selectedList?.label}
            onSelect={handleOptionClick}
            options={listOptions}
          />

          <div className='mt-4 text-center flex justify-between space-x-4'>
            <div className='flex flex-1'>
              <Button
                size='sm'
                text='Cancel'
                onClick={closeAddListModal}
                variant='outline'
                className='w-full'
              />
            </div>

            <div className='flex flex-1'>
              <Button
                size='sm'
                text='Save'
                type='submit'
                className='hover:bg-primary-medium w-full'
                onClick={() => {
                  if (selectedList?.label === 'My Contacts') {
                    handleSaveToMyContactList(contactIds);
                    closeAddListModal();
                    cancelOnSelectedRowsChange();
                  } else {
                    handleSaveToList(contactIds, selectedList?.value as string);
                    cancelOnSelectedRowsChange();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete contact from list modal */}
      <ConfirmationModal
        show={openDeleteContactFromList}
        setShow={setOpenDeleteContactFromList}
        title='Remove Contact'
        content='Are you sure you want to remove this contact from list?'
        onConfirm={() => {
          deleteContactFromList(listId as string, contactIds);
          setOpenDeleteContactFromList(false);
          setSelectedContacts({});
        }}
        confirmText='Delete'
      />
    </MainContainer>
  );
};
export default observer(Table);
