import { Button } from '@/components/ui/button';
import routeNames from '@/routes/routeNames';
import { AuthStore } from '@/state/AuthenticationStore';
import { replaceRouteParam } from '@/utils/routes';
import {
  AlertCircle,
  ArrowLeft,
  Clock,
  Pencil,
  Save,
  Send,
  Settings,
  X,
} from 'lucide-react';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Label } from '@/components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { ScrollArea } from '@/components/ui/scroll-area';
import { emailMarketingStore } from '@/state/EmailMarketingStore';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { contactStore } from '@/state/ContactStore';
import { observer } from 'mobx-react-lite';

interface CampaignHeaderProps {
  channel: string | undefined;
  setIsNextClicked: (value: boolean) => void;
  setIsSendClicked: (value: boolean) => void;
}

const CampaignHeader: FC<CampaignHeaderProps> = ({
  channel,
  setIsNextClicked,
  setIsSendClicked,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const steps = [`${channel}`, 'campaign-message', 'campaign-review'];
  // const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  // const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('sendNow');
  // const [testEmail, setTestEmail] = useState('');
  const [campaignNameModalOpen, setCampaignNameModalOpen] =
    useState<boolean>(false);
  const [campaignName, setCampaignName] = useState<string>(
    emailMarketingStore.campaignName,
  );
  const [displayErrorMsg, setDisplayErrorMsg] = useState<boolean>(false);

  const currentIndex = steps.findIndex(step =>
    location.pathname.endsWith(step),
  );

  const goNext = () => {
    setIsNextClicked(true);
  };

  const send = () => {
    setIsSendClicked(true);
  };

  const goToCampaignManager = () => {
    navigate(
      replaceRouteParam(
        `${routeNames.dashboard.emailMarketing}/campaigns`,
        'workspaceId',
        AuthStore.user_workspace_info.active_workspace.workspace._id,
      ),
    );
  };

    const clearCampaignValues = () => {
      contactStore.setCampaignSenderList([])
      emailMarketingStore.setEmailParams({
        sender_name: '',
        preview_text: '',
        subject_line: '',
      })
      emailMarketingStore.setTemplate(null)
      emailMarketingStore.setTemplateOnDemand(null)
      emailMarketingStore.setSelectedRecipients([])
      emailMarketingStore.setExcludedRecipients([])
      emailMarketingStore.setFinalList([])
    }

  const changeCampaignName = () => {
    if (campaignName == '') {
      setDisplayErrorMsg(true);
    } else {
      emailMarketingStore.setCampaignName(campaignName);
      setCampaignNameModalOpen(false);
    }
  };

  return (
    <div>
      <div className='container mx-auto min-w-full bg-[#033eb5] px-2 text-white flex items-center justify-between h-16 gap-4'>
        <div className='flex items-center max-w-[290px] gap-4'>
          <Button
            onClick={() => navigate(-1)}
            variant='ghost'
            size='icon'
            className='text-white hover:text-white/80'
          >
            <ArrowLeft className='h-4 w-4' />
          </Button>
          <h1 className='text-lg truncate font-semibold'>
            {emailMarketingStore.campaignName}
          </h1>
        </div>
        <div className='flex flex-col items-center gap-1'>
          <span className='text-md font-medium'>
            {currentIndex == 0
              ? 'Recipients'
              : currentIndex == 1
              ? 'Message'
              : 'Review'}
          </span>
          {/* <div className='flex items-center gap-1'>
            <div
              className={`h-2 w-2 rounded-full cursor-pointer ${
                currentIndex == 0 ? ' bg-green-500' : 'bg-gray-400/50'
              }`}
              onClick={() => {
                if (currentIndex !== 0) {
                  navigate(
                    replaceRouteParam(
                      `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}`,
                      'workspaceId',
                      AuthStore.user_workspace_info.active_workspace.workspace
                        ._id,
                    ),
                  );
                }
              }}
            ></div>
            <div
              className={`h-2 w-2 rounded-full cursor-pointer  ${
                currentIndex == 1 ? ' bg-green-500' : 'bg-gray-400/50'
              }`}
              onClick={() => {
                if (currentIndex !== 1) {
                  navigate(
                    replaceRouteParam(
                      `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}/campaign-message`,
                      'workspaceId',
                      AuthStore.user_workspace_info.active_workspace.workspace
                        ._id,
                    ),
                  );
                }
              }}
            ></div>
            <div
              className={`h-2 w-2 rounded-full cursor-pointer ${
                currentIndex == 2 ? ' bg-green-500' : 'bg-gray-400/50'
              }`}
              onClick={() => {
                if (currentIndex !== 2) {
                  navigate(
                    replaceRouteParam(
                      `${routeNames.dashboard.emailMarketing}/campaign-flow/${channel}/campaign-review`,
                      'workspaceId',
                      AuthStore.user_workspace_info.active_workspace.workspace
                        ._id,
                    ),
                  );
                }
              }}
            ></div>
          </div> */}
        </div>
        <div className='flex items-center max-w-full gap-2'>
          <div className='flex text-nowrap items-center gap-2 text-md'>
            <Clock className='h-4 w-4' />
            Last saved: now
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant='ghost'
                size='icon'
                className='text-white hover:bg-sky-500/20 hover:text-white'
              >
                <Settings className='h-5 w-5' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              {/* <DropdownMenuItem
                className='text-md'
                // onSelect={() => setIsTestModalOpen(true)}
              >
                <Send className='h-4 w-4 mr-2' />
                Send Test
              </DropdownMenuItem> */}
              <DropdownMenuItem
                onClick={() => setCampaignNameModalOpen(true)}
                className='text-md'
              >
                <Pencil className='h-4 w-4 mr-2' />
                Rename
              </DropdownMenuItem>
              {/* <DropdownMenuItem className='text-md'>
                <Save className='h-4 w-4 mr-2' />
                Save Draft
              </DropdownMenuItem> */}
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            variant='outline'
            onClick={() => {
              goToCampaignManager();
              clearCampaignValues()
            }}
            className='bg-secondary text-white border-white hover:text-white hover:bg-sky-500/20'
          >
            Exit
          </Button>
          {currentIndex == 2 && (
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant='outline'
                  className='w-full md:w-auto text-black'
                >
                  Schedule or send
                </Button>
              </SheetTrigger>
              <SheetContent className='w-[300px] sm:w-[400px] max-h-screen flex flex-col'>
                <SheetHeader>
                  <SheetTitle>Schedule or send campaign</SheetTitle>
                </SheetHeader>
                <ScrollArea className='flex-grow py-4'>
                  <div>
                    <div className='flex space-x-6 mb-6'>
                      <div
                        // onClick={() => setSelectedTab('schedule')}
                        className={`flex flex-col opacity-75 p-2 border rounded-[10px] cursor-pointer ${
                          selectedTab === 'schedule'
                            ? 'border-secondary'
                            : 'border-gray-300'
                        }`}
                      >
                        <span className='font-medium text-[16px] text-black/90'>
                          Schedule
                        </span>
                        <p className='font-normal text-[12px] text-black/50'>
                          Choose a future date and time to send
                        </p>
                      </div>

                      <div
                        onClick={() => setSelectedTab('sendNow')}
                        className={`flex flex-col p-2 border rounded-[10px] cursor-pointer ${
                          selectedTab === 'sendNow'
                            ? 'border-secondary'
                            : 'border-gray-300'
                        }`}
                      >
                        <span className='font-medium text-[16px] text-black/90'>
                          Send now
                        </span>
                        <p className='font-normal text-[12px] text-black/50'>
                          Start sending your campaign immediately
                        </p>
                      </div>
                    </div>
                    {selectedTab === 'schedule' && (
                      <div className='mt-2 space-y-2'>
                        <Label
                          htmlFor='sendType'
                          className='flex items-center space-x-2'
                        >
                          <span>Type </span>{' '}
                          <span className='text-red-500'>*</span>
                        </Label>
                        <Select name='sendType' required>
                          <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                            <SelectValue placeholder='Select type' />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value='Fixed send time'>
                              Fixed send time
                            </SelectItem>
                          </SelectContent>
                        </Select>
                        <Label
                          htmlFor='date'
                          className='flex items-center space-x-2'
                        >
                          <span>Date </span>{' '}
                          <span className='text-red-500'>*</span>
                        </Label>
                        <Input
                          type='date'
                          placeholder='date'
                          className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                        />
                        <Label
                          htmlFor='time'
                          className='flex items-center space-x-2'
                        >
                          <span>Time</span>
                        </Label>
                        <Input
                          type='time'
                          placeholder='time'
                          className='focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 focus-within:border focus-within:border-secondary'
                        />
                        <Label
                          htmlFor='sendType'
                          className='flex items-center space-x-2'
                        >
                          <span>Time zone </span>
                        </Label>
                        <Select name='Timezone' required>
                          <SelectTrigger className='focus:ring-0 focus:ring-offset-0 focus-within:border focus-within:border-secondary'>
                            <SelectValue placeholder='Select time zone' />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value='Fixed send time'>
                              {"Recipient's Local Time Zone"}
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    )}
                  </div>
                </ScrollArea>
                {selectedTab === 'schedule' && (
                  <SheetFooter className='flex !justify-start items-center'>
                    <Button type='button' variant='outline'>
                      Cancel
                    </Button>
                    <Button
                      type='button'
                      disabled={true}
                      className='text-white bg-black/90 hover:bg-black/80'
                    >
                      Schedule campaign
                    </Button>
                  </SheetFooter>
                )}
                {selectedTab === 'sendNow' && (
                  <SheetFooter className='flex !justify-start items-center'>
                    <SheetClose>
                      <Button type='button' variant='outline'>
                       Cancel
                      </Button>
                    </SheetClose>
                    <Button
                      onClick={send}
                      type='button'
                      className='text-white bg-black/90 hover:bg-black/80'
                      disabled={emailMarketingStore.loading}
                    >
                      Send
                    </Button>
                  </SheetFooter>
                )}
              </SheetContent>
            </Sheet>
          )}

          <Dialog
            open={campaignNameModalOpen}
            onOpenChange={setCampaignNameModalOpen}
          >
            <DialogContent className='bg-white flex flex-col border-none p-8 max-w-[600px] max-h-[90vh] overflow-y-auto [&>button]:hidden'>
              <DialogHeader className='flex flex-row justify-between items-center'>
                <DialogTitle className='text-2xl font-semibold text-secondary'>
                  Rename Campaign
                </DialogTitle>
                <DialogClose asChild>
                  <Button variant='ghost' size='icon'>
                    <X className='h-6 w-6' />
                  </Button>
                </DialogClose>
              </DialogHeader>
              <div className='w-full h-full flex flex-row'>
                <div className='w-full'>
                  <Label>Campaign Name</Label>
                  <Input
                    className={`${
                      displayErrorMsg ? 'border border-red-700' : ''
                    }`}
                    value={campaignName}
                    onChange={e => {
                      setCampaignName(e.target.value);
                    }}
                  />
                  {displayErrorMsg && (
                    <div className='flex mt-1 flex-row'>
                      <AlertCircle className=' h-4 w-4 mr-1 text-red-700' />{' '}
                      <span className='text-xs text-red-700'>
                        This field is required.
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <DialogFooter className='flex flex-row-reverse'>
                <Button
                  variant={'outline'}
                  onClick={() => setCampaignNameModalOpen(false)}
                >
                  <p>Cancel</p>
                </Button>
                <Button
                  className='bg-secondary text-white hover:bg-secondary/80'
                  variant={'default'}
                  onClick={changeCampaignName}
                >
                  <p className='text-white'>Change name</p>
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          {currentIndex != 2 && (
            <Button
              onClick={goNext}
              className='bg-white text-[#033eb5] hover:bg-white/90 border-2 border-white shadow-md'
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(CampaignHeader);
