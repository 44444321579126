import Button from '@/components/atoms/Button';
import EmptyList from '@/components/atoms/EmptyList';
import { DataTable } from '@/components/template/DataTable';
import { CustomField } from '@/types/customField.types';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

type CustomFieldsTableProps = {
  data?: CustomField[];
  onDelete: (id: string) => void;
  onEdit: (data: any) => void;
};
const columnHelper = createColumnHelper<CustomField>();

const CustomFieldsTable: FC<CustomFieldsTableProps> = ({
  data = [],
  onDelete,
  onEdit,
}) => {
  const customColumns = useMemo(
    () => [
      columnHelper.accessor('field', {
        id: 'field',
        cell: info => <p>{info?.getValue()}</p>,
        header: () => <span>Field Name</span>,
      }),
      columnHelper.accessor('type', {
        id: 'type',
        cell: info => <p className='uppercase'>{info?.getValue()}</p>,
        header: () => <span>Field Type</span>,
      }),
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        enableSorting: false,
        cell: info => <p>{format(new Date(info?.getValue()), 'dd/MM/yyyy')}</p>,
        header: () => <span> Created At</span>,
      }),
      columnHelper.accessor('updatedAt', {
        id: 'updatedAt',
        enableSorting: false,
        cell: info => <p>{format(new Date(info?.getValue()), 'dd/MM/yyyy')}</p>,
        header: () => <span>Last Modified</span>,
      }),
      columnHelper.accessor('_id', {
        id: 'actions',
        enableSorting: false,
        cell: info => (
          <div className='flex flex-row justify-end space-x-2'>
            <Button
              className='w-full'
              onClick={() => onEdit(info?.row?.original)}
              text='Edit'
            />
            <Button
              className='w-full bg-warning hover:bg-warning/80'
              onClick={() => onDelete(info?.getValue())}
              text='Delete'
            />
          </div>
        ),
        header: () => <span>Actions</span>,
      }),
    ],
    [columnHelper, data],
  );

  return (
    <DataTable
      columns={customColumns as any}
      data={data}
      headerClassName='bg-gray-100 text-lightBlack font-medium text-md first:w-[initial] py-2 even:w-auto last:w-auto'
      showPagination={data?.length > 0}
      noDataComponent={
        <EmptyList
          listName={'Custom Fields'}
          title='There are no'
          subTitle='Add '
          height='60vh'
        />
      }
    />
  );
};
export default observer(CustomFieldsTable);
