import Icon from '@/assets/Icons';
import Icons from '@/assets/Icons/icons.json';
import EmptyList from '@/components/atoms/EmptyList';
import Search from '@/components/atoms/Search';
import Spinner from '@/components/atoms/Spinner';
import Text from '@/components/atoms/Text';
import DropdownMenu from '@/components/molecules/DropdownFilter';
import { FilterTabOption } from '@/components/molecules/FilterTabs';
import NewContact from '@/components/molecules/NewContact';
import ProfileTab from '@/components/molecules/ProfileTab';
import { Tab, TabPanel } from '@/components/molecules/Tab';
import { customStyles } from '@/components/molecules/Table/styles';
import { useContact } from '@/hooks/useContact';
import { useCustomField } from '@/hooks/useCustomField';
import { contactStore } from '@/state/ContactStore';
import { CustomField } from '@/types/customField.types';
import { User } from '@/types/user.types';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { BsSearch } from 'react-icons/bs';
import { MdGridOn } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Filter } from './filter';
import { TableListFilter } from './menu';
export type ContactType = 'all' | 'my' | 'recently-viewed' | 'list';

type Callback = (contactId: string) => void;

const staticColumns = (cb: Callback) => [
  {
    id: 1,
    name: 'Name',
    dataKey: 'user_name',
    selector: (row: any) => row['user_name'],
    cell: (row: User) => {
      return (
        <div className='cursor-pointer py-1 w-full'>
          <ProfileTab
            name={row.user_name}
            imageURL={row?.profile_picture_url as string}
            onClick={() => cb(row._id as string)}
          />
        </div>
      );
    },
    sortable: true,
    omit: false,
  },
  {
    id: 2,
    name: 'Phone',
    dataKey: 'phone_number',
    selector: (row: any) => row['phone_number'],
    cell: (row: any) => {
      return (
        <div className='text-neutral-900 text-[14px]  font-medium leading-tight truncate'>
          {row.phone_number || '-'}
        </div>
      );
    },
    sortable: true,
    omit: false,
  },
  {
    id: 3,
    name: 'Email',
    dataKey: 'email',
    selector: (row: any) => row['email'],
    cell: (row: any) => {
      return (
        <div className='text-neutral-900 text-[14px]  font-medium leading-tight truncate'>
          {row.email || '-'}
        </div>
      );
    },
    sortable: true,
    omit: false,
  },
  {
    id: 4,
    name: 'Company',
    dataKey: 'company_name',
    selector: (row: any) => row['company_name'],
    cell: (row: any) => {
      return (
        <div className='text-neutral-900 text-[14px]  font-medium leading-tight truncate'>
          {row.company_name || '-'}
        </div>
      );
    },
    sortable: true,
    omit: false,
  },
  {
    id: 5,
    name: 'City',
    dataKey: 'city',
    selector: (row: any) => row['city'],
    cell: (row: any) => {
      return (
        <div className='text-neutral-900 text-[14px]  font-medium leading-tight truncate'>
          {row.city || '-'}
        </div>
      );
    },
    sortable: true,
    omit: false,
  },
  {
    id: 6,
    name: 'Created date',
    dataKey: 'createdAt',
    selector: (row: any) => row['createdAt'],
    cell: (row: any) => {
      return (
        <div className='text-neutral-900 text-[14px]  font-medium leading-tight truncate'>
          {new Date(row.createdAt).toDateString() || '-'}
        </div>
      );
    },
    sortable: true,
    omit: false,
  },
  {
    id: 7,
    name: 'Last seen',
    dataKey: 'last_seen',
    selector: (row: any) => row['last_seen'],
    cell: (row: any) => {
      return (
        <div className='text-neutral-900 text-[14px]  font-medium leading-tight truncate'>
          {new Date(row.last_seen).toDateString() || '-'}
        </div>
      );
    },
    sortable: true,
    omit: false,
  },
];

const MobileView: FC = () => {
  const {
    open,
    openImport,
    createContact,
    importContacts,
    closeModal,
    openModal,
    openModalImport,
    closeModalImport,
    getContacts,
    handleViewContact,
  } = useContact();
  const [columns, setColumns] = useState(() =>
    staticColumns(handleViewContact),
  );
  const { getCustomFields, customFields } = useCustomField();
  const [contactType, setContactType] = useState<ContactType>('all');
  const [title, setTitle] = useState<string>('All Contacts');
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showSearch, setShowSearch] = useState(false);
  const { listId } = useParams();
  const options: FilterTabOption[] = [
    { id: 0, title: 'Table view', icon: null, type: 'tab' },
    { id: 1, title: 'Board view', icon: null, type: 'tab', disabled: true },
  ];

  const OPTIONS_FILTERS = [
    {
      label: 'Table view',
      value: 'Table view',
    },
    {
      label: 'Board view',
      value: 'Board view',
    },
  ];
  const handleDropdownChange = (index: number) => {
    setActiveTab(index);
  };
  const handleTabChange = (tabIndex: number) => {
    let tab: ContactType;
    switch (tabIndex) {
      case 0:
        tab = 'all';
        setTitle('All Contacts');
        break;
      case 1:
        tab = 'my';
        setTitle('My Contacts');
        break;
      case 2:
        tab = 'recently-viewed';
        setTitle('Recently Viewed');
        break;
      default:
        tab = 'all';
        setTitle('All Contacts');
        break;
    }
    setContactType(tab);
    // const filter = `?contactType=${tab}`;
    // Temporary comment this code to prevent re-renders of table
    // Lets pass the contacts as prop in the future to prevent re-renders.
    // getContacts(filter);
  };

  const handleSearch = (event: ChangeEvent) => {
    //
  };

  const handleOmitField = (_: any, name: string) => {
    setColumns(prevCol => {
      const newCol = prevCol.map(col => {
        if (col.name === name) {
          col.omit = !col.omit;
        }
        return col;
      });
      return newCol;
    });
  };

  useEffect(() => {
    if (customFields.length) {
      const customColumnFields = customFields.map((customField, index) => {
        return {
          id: columns.length + (index + 1),
          name: customField.field,
          dataKey: customField.field,
          selector: (row: CustomField) =>
            row[customField.field as keyof CustomField],
          cell: (row: any) => {
            return (
              <div className='text-neutral-900 text-[14px]  font-medium leading-tight truncate'>
                {customField.field === 'select'
                  ? row[customField.field]
                  : row[customField.field] || '-'}
              </div>
            );
          },
          sortable: true,
          omit: true,
        };
      });
      setColumns([...columns, ...customColumnFields]);
    }
  }, [customFields?.length]);

  const handleOnFilter = async (values: {
    field: string;
    operator: string;
    value: string;
  }) => {
    const encodedValue = encodeURIComponent(values.value);
    const searchQuery = `?key=${values.field}&operator=${values.operator}&value=${encodedValue}`;
    getContacts(searchQuery?.toLowerCase());
  };

  useEffect(() => {
    const queryParams = listId
      ? `?list=${listId}`
      : `?contactType=${contactType}`;
    // getContacts(`${queryParams}`);
  }, [contactType]);

  useEffect(() => {
    getCustomFields();
  }, []);

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between items-center mb-4 px-4'>
        <Text size='lg' className='text-black'>
          Contacts
        </Text>
        <div className='flex items-center space-x-2 cursor-pointer'>
          <NewContact
            contactType={contactType}
            open={open}
            openImport={openImport}
            handleCreateContact={createContact}
            handleImportContact={importContacts}
            openModal={openModal}
            closeModal={closeModal}
            openModalImport={openModalImport}
            closeModalImport={closeModalImport}
          />
        </div>
      </div>
      <Tab onTabChange={handleTabChange}>
        <TabPanel title='All contacts'></TabPanel>
        <TabPanel title='My contacts'></TabPanel>
        <TabPanel title='Recently viewed'></TabPanel>
      </Tab>
      <div className='border border-x-0 border-t-0 border-border my-4'></div>
      {contactStore.fetchingContact ? (
        <div className='h-[50vh] flex justify-center bg-white rounded-[16px] m-6'>
          <Spinner color='blue' size={50} />
        </div>
      ) : (
        <>
          <div className='flex justify-between items-center mb-2 px-4'>
            <div className='flex flex-col'>
              <Text size='md' className='text-black'>
                {' '}
                {`${title} (${contactStore.contacts.length})`}
              </Text>
              <div className='my-2' aria-disabled>
                <DropdownMenu
                  icon={<MdGridOn className='w-4 h-4 mr-2' />}
                  options={options}
                  currentOption={OPTIONS_FILTERS[activeTab].value}
                  onChange={handleDropdownChange}
                  activeTab={activeTab}
                />
              </div>
            </div>
            <div className='flex space-x-4'>
              <div
                onClick={() => setShowSearch(!showSearch)}
                className='w-10 h-10 bg-mainGray/10 border border-border rounded-[4px] cursor-pointer flex items-center justify-center'
              >
                <BsSearch className='text-neutral-700 h-4 w-4' />
              </div>
              <Filter onFilter={handleOnFilter} />
              <TableListFilter
                columns={columns}
                customFields={customFields}
                handleOmitField={handleOmitField}
              />
            </div>
          </div>
          <div
            className={`${
              !showSearch ? 'h-0' : 'h-auto my-2'
            } px-4 duration-500 ease-in-out`}
          >
            <Search
              placeholder='Search Contacts'
              transparent
              showCommand={false}
              onChange={handleSearch}
            />
          </div>
          <DataTable
            columns={columns}
            data={contactStore.contacts}
            fixedHeader
            customStyles={{
              ...customStyles,
            }}
            pagination
            paginationComponentOptions={{
              rowsPerPageText: 'Records per page:',
            }}
            dense={true}
            sortIcon={
              <div className='flex items-center'>
                <div className='absolute right-2'>
                  <Icon icon={Icons.arrowDown} />
                </div>
              </div>
            }
            noDataComponent={
              <EmptyList
                listName={title || ''}
                title='There are no contacts in'
                subTitle='Add contacts to see them in '
                height='75vh'
              />
            }
          />
          <div className='border border-x-0 border-t-0 border-border my-4'></div>
        </>
      )}
    </div>
  );
};
export default observer(MobileView);
