import Button from '@/components/atoms/Button';
import Modal from '@/components/atoms/modal';
import Search from '@/components/atoms/Search';
import CustomFieldForm from '@/components/template/forms/CustomFieldForm';
import {
  CreateCustomFieldPayload,
  useCustomField,
} from '@/hooks/useCustomField';
import { AuthStore } from '@/state/AuthenticationStore';
import { customFieldStore } from '@/state/CustomFieldStore';
import { CustomField } from '@/types/customField.types';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import CustomFieldsTable from '../Components/CustomFieldTable';
import Spinner from '@/components/atoms/Spinner';

const customFieldType = [
  {
    _id: 1,
    type: 'number',
  },
  {
    _id: 2,
    type: 'text',
  },

  {
    _id: 4,
    type: 'url',
  },
  {
    _id: 5,
    type: 'date',
  },
  {
    _id: 6,
    type: 'checkbox',
  },
  {
    _id: 7,
    type: 'select',
  },
];

const CustomFields = () => {
  const [updateCustomFieldData, setUpdateCustomFieldData] =
    useState<CustomField | null>(null);

  const {
    open,
    customFields,
    setCustomFields,
    openModal,
    closeModal,
    createCustomField,
    getCustomFields,
    deleteCustomField,
    updateCustomField,
  } = useCustomField();
  const isLoading = false;

  const handleSearch = (e: any) => {
    e.preventDefault();
    const search = e.target.value?.toLowerCase();
    if (search === '') {
      setCustomFields(customFieldStore.customFields ?? []);
      return;
    }

    const filteredFields = customFields.filter(customField => {
      const field = customField.field?.toLowerCase();
      return field.includes(search?.toLowerCase()) || field.startsWith(search);
    });

    setCustomFields(filteredFields);
  };

  const handleEdit = (data: CustomField) => {
    setUpdateCustomFieldData(data);
    openModal();
  };
  const handleClearCustomFieldData = () => {
    setTimeout(() => {
      setUpdateCustomFieldData(null);
    }, 500);
  };

  useEffect(() => {
    getCustomFields();
  }, [customFieldStore?.customFields?.length]);

  if (isLoading) return <div>Loading...</div>;
  const workspace_name =
    AuthStore.user_workspace_info?.active_workspace?.workspace?.company_name;

  return (
    <div className='flex flex-col w-full h-full overflow-hidden rounded-[16px] shadow  bg-white'>
      {customFieldStore?.loading ? (
        <div className='h-full w-full flex flex-row justify-center items-center'>
          <Spinner size={40} color={'#033EB5'} />
        </div>
      ) : (
        <>
          <div className='px-5 pt-5 sticky-top  z-[99999]'>
            <div className='flex flex-row justify-between'>
              <div className='flex flex-row items-center'>
                <h1 className='text-[24px] leading-[36px] font-[500]'>
                  Custom Fields
                </h1>
                <span className='text-[24px] leading-[36px] font-[500] text-gray-400 mx-1'>
                  for
                </span>
                <h1 className='text-[24px] leading-[36px]  font-[500] text-gray-400'>
                  {workspace_name}
                </h1>
              </div>
              <div>
                <Button text='Add Custom Field' onClick={openModal} />
              </div>
            </div>
            <div className='flex flex-row items-center 2xl:space-x-12 xl:space-x-10 my-4'>
              <span className='text-md'>
                {customFields?.length} Custom Fields
              </span>
            </div>

            <div className='flex flex-row justify-end items-center'>
              <div>
                <Search
                  showCommand={false}
                  transparent={true}
                  placeholder='Search By Field'
                  type='search'
                  onChange={handleSearch}
                  onKeyDown={e => {
                    if (e.code === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSearch(e);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className='h-full overflow-y-hidden py-[24px]'>
            <CustomFieldsTable
              data={customFields}
              onDelete={deleteCustomField}
              onEdit={handleEdit}
            />
          </div>
        </>
      )}

      <Modal
        openModal={openModal}
        closeModal={() => {
          closeModal();
          handleClearCustomFieldData();
        }}
        show={open}
        title={
          updateCustomFieldData ? 'Edit Custom Field' : 'Create Custom Field'
        }
        className='z-[10000] inline-block py-6 my-8 w-[100%] max-w-[500px] overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-[7px]'
      >
        <CustomFieldForm
          customFieldType={customFieldType}
          handleSubmit={
            updateCustomFieldData
              ? (data: CreateCustomFieldPayload) => {
                  updateCustomField(updateCustomFieldData._id, data);
                  handleClearCustomFieldData();
                }
              : createCustomField
          }
          updateCustomFieldData={updateCustomFieldData}
        />
      </Modal>
    </div>
  );
};
export default observer(CustomFields);
