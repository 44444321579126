import { FC, DragEvent, ChangeEvent } from 'react';

import Button from '@/components/atoms/Button';
import { UploadIcon } from '@/components/atoms/Icons/UploadIcon';
import { WidgetConfigStore } from '@/state/WidgetConfigStore';
import { observer } from 'mobx-react-lite';
import { useStyle } from './hooks';
import { useDragNDrop } from '@/hooks/useDragNDrop';
import Spinner from '@/components/atoms/Spinner';

const Style: FC<{ handleCancel: () => void }> = ({ handleCancel }) => {
  const {
    handleHeaderColorInputChange,
    handleBorderColorInputChange,
    handleButtonColorInputChange,
    handleHeaderTextColorInputChange,
    handlePublish,
    sending,
    sync,
    upload_percentage,
    progress,
    handleUpload,
    handleUploadWidget,
  } = useStyle();

  const {
    isFileOver: fileHover,
    setIsFileOver: setFileHover,
    handleDragOver: dragOver,
    handleDragLeave: dragLeave,
  } = useDragNDrop({
    imageTypes: ['image/png'],
  });

  const {
    isFileOver: fileOver,
    setIsFileOver: setFileOver,
    handleDragOver: dragAbove,
    handleDragLeave: dragExit,
  } = useDragNDrop({
    imageTypes: ['image/png'],
  });

  const handleDrop = (e: DragEvent<HTMLDivElement>, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (id == 'logo') {
      setFileHover(false);
    } else {
      setFileOver(false);
    }

    const files = e.dataTransfer.files;

    if (files.length > 0) {
      const fileInput = document.getElementById(id) as HTMLInputElement;
      const dataTransfer = new DataTransfer();

      for (let i = 0; i < files.length; i++) {
        dataTransfer.items.add(files[i]);
      }

      fileInput.files = dataTransfer.files;

      if (id == 'logo') {
        WidgetConfigStore.set_not_show_widget(false);
        handleUpload({ target: fileInput } as ChangeEvent<HTMLInputElement>);
      } else {
        WidgetConfigStore.set_not_show_widget(true);
        handleUploadWidget({
          target: fileInput,
        } as ChangeEvent<HTMLInputElement>);
      }
    }
  };

  return (
    <div className='w-full auto flex flex-col h-full '>
      <div className='w-full h-full my-[24px]'>
        <label className='font-[500] text-[14px] leading-[18px] pb-[10px] text-[#656971] text-opacity-90 w-[fit-content]'>
          Widget Brand Logo
        </label>
        <div
          onDragLeave={dragLeave}
          onDragOver={dragOver}
          onDrop={e => handleDrop(e, 'logo')}
          className={`relative rounded-[4px] h-[auto] ${
            fileHover ? 'border-blue' : ''
          } py-[2rem] w-full flex flex-col justify-center items-center border-[2px] border-[#E6E8EB] border-dashed bg-white my-[10px]`}
        >
          {WidgetConfigStore.config.value.images.brand_logo_url ? (
            <>
              <img
                className='h-[14vh] mb-[1rem]'
                src={WidgetConfigStore.config.value.images.brand_logo_url}
                alt='Branding'
              />
              <Button
                type='button'
                text='Remove'
                size='sm'
                disabled={sending}
                onClick={() => {
                  WidgetConfigStore.removeBrandLogoUrl();
                }}
                variant='outline'
              />
            </>
          ) : sending ? (
            <div className='py-10'>
              <p className='text-gray-375'>{progress}%</p>
              <div
                style={{
                width: `${progress}%`,
                }}
                className={`justify-self-start   h-[5px] bg-[#033EB5] rounded-lg transition-all`}
              ></div>
            </div>
          ) : (
            <label
              htmlFor='logo'
              className='relative  flex flex-col justify-center items-center  cursor-pointer'
            >
              <UploadIcon />
              <p className='text-[14px] font-[500] text-[#161518] mt-[8px] mb-[6px]'>
                <span className='text-[14px] font-[500] text-[#1068EF]'>
                  Upload a logo{' '}
                </span>{' '}
                or drag and drop
              </p>
              <p className='font-[400] text-[12px] text-[#656971]'>
                The resolution of the logo must be 50x100
              </p>
              <input
                type='file'
                id='logo'
                disabled={sending}
                onClick={() => WidgetConfigStore.set_not_show_widget(false)}
                onChange={handleUpload}
                className='absolute left-0 right-0 w-[20px] invisible'
              />
            </label>
          )}
        </div>

        <label className='font-[500] text-[14px] leading-[18px] pb-[10px] text-[#656971] text-opacity-90 w-[fit-content] my-[15px]'>
          Widget Button Logo
        </label>
        <div
          onDragLeave={dragExit}
          onDragOver={dragAbove}
          onDrop={e => handleDrop(e, 'logo_url')}
          className={`relative rounded-[4px] h-[auto] py-[2rem] ${
            fileOver ? 'border-blue' : ''
          } w-full flex flex-col justify-center items-center border-[2px] border-[#E6E8EB] border-dashed bg-white my-[10px]`}
        >
          {WidgetConfigStore.config.value.images.widget_logo_url ? (
            <>
              <img
                className='h-[14vh] mb-[1rem]'
                src={WidgetConfigStore.config.value.images.widget_logo_url}
                alt='Branding'
              />
              <Button
                type='button'
                text='Remove'
                size='sm'
                disabled={sync}
                onClick={() => {
                  WidgetConfigStore.removeWidgetLogoUrl();
                }}
                variant='outline'
              />
            </>
          ) : sync ? (
            <div className='py-10'>
              <p className='text-gray-375'>{upload_percentage}%</p>
              <div
                style={{
                width: `${upload_percentage}%`,
                }}
                className={`justify-self-start  h-[5px] bg-[#033EB5] rounded-lg transition-all`}
              ></div>
            </div>
          ) : (
            <label
              htmlFor='logo_url'
              className='relative  flex flex-col justify-center items-center  cursor-pointer'
            >
              <UploadIcon />
              <p className='text-[14px] font-[500] text-[#161518] mt-[8px] mb-[6px]'>
                <span className='text-[14px] font-[500] text-[#1068EF]'>
                  Upload a logo{' '}
                </span>{' '}
                or drag and drop
              </p>
              <p className='font-[400] text-[12px] text-[#656971]'>
                The resolution of the logo must be 50x100
              </p>
              <input
                type='file'
                id='logo_url'
                disabled={sync}
                onClick={() => WidgetConfigStore.set_not_show_widget(true)}
                onChange={handleUploadWidget}
                className='absolute left-0 right-0 w-[20px] invisible'
              />
            </label>
          )}
        </div>
      </div>
      <div className='flex flex-row w-full mb-[24px]'>
        <div className='flex flex-col mt-[15px] mr-[24px] flex-1'>
          <label className='font-[500] text-[14px] leading-[18px] pb-[10px] text-[#656971] text-opacity-90 w-[fit-content]'>
            Background color
          </label>
          <div
            onClick={() => WidgetConfigStore.set_not_show_widget(false)}
            className='rounded-[4px] border-gray-300 bg-white border-[1px] border-b-[2px] flex  items-center justify-center h-[40px] font-medium p-3'
          >
            <div className='relative rounded-full bg-headerBgColor w-[16px] h-[16px] shrink-0 border   mr-[8px]'>
              <input
                onChange={handleHeaderColorInputChange}
                type='color'
                value={WidgetConfigStore.config.value?.colors.header_bg_color}
                className='opacity-0 rounded-full w-full h-full bg-transparent absolute top-0 right-0 left-0 bottom-0'
              />
            </div>
            <input
              type='text'
              onChange={handleHeaderColorInputChange}
              value={WidgetConfigStore.config.value?.colors.header_bg_color}
              className='w-full h-full outline-none uppercase  border-0  bg-transparent font-[500] text-[14px]'
            />
          </div>
        </div>
        <div className='flex flex-col mt-[15px] mr-[24px] flex-1'>
          <label className='font-[500] text-[14px] leading-[18px] pb-[10px] text-[#656971] text-opacity-90 w-[fit-content]'>
            Header text color
          </label>

          <div
            onClick={() => WidgetConfigStore.set_not_show_widget(false)}
            className='rounded-[4px] border-gray-300 bg-white border-[1px] border-b-[2px] flex  items-center justify-center h-[40px] font-medium p-3'
          >
            <div className='relative rounded-full  bg-headerTextColor w-[16px] h-[16px] shrink-0 border  border-gray-300 mr-[8px]'>
              <input
                onChange={handleHeaderTextColorInputChange}
                type='color'
                value={WidgetConfigStore.config.value?.colors.header_text_color}
                className='opacity-0 rounded-full w-full h-full bg-transparent absolute top-0 right-0 left-0 bottom-0'
              />
            </div>
            <input
              type='text'
              onChange={handleHeaderTextColorInputChange}
              value={WidgetConfigStore.config.value?.colors.header_text_color}
              className='w-full h-full outline-none uppercase  border-0  bg-transparent font-[500] text-[14px]'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-row w-full mb-6'>
        <div className='flex flex-col mt-[15px] mr-[24px] flex-1'>
          <label className='font-[500] text-[14px] leading-[18px] pb-[10px] text-[#656971] text-opacity-90 w-[fit-content]'>
            Border color
          </label>
          <div
            onClick={() => WidgetConfigStore.set_not_show_widget(false)}
            className='rounded-[4px] border-gray-300 bg-white border-[1px] border-b-[2px] flex  items-center justify-center h-[40px] font-medium p-3'
          >
            <div className='relative rounded-full bg-borderColor w-[16px] h-[16px] shrink-0 border   mr-[8px]'>
              <input
                onChange={handleBorderColorInputChange}
                type='color'
                value={WidgetConfigStore.config.value?.colors.border_color}
                className='opacity-0 rounded-full w-full h-full bg-transparent absolute top-0 right-0 left-0 bottom-0'
              />
            </div>
            <input
              type='text'
              onChange={handleBorderColorInputChange}
              value={WidgetConfigStore.config.value?.colors.border_color}
              className='w-full h-full outline-none uppercase  border-0  bg-transparent font-[500] text-[14px]'
            />
          </div>
        </div>

        <div className='flex flex-col mt-[15px] mr-[24px] flex-1'>
          <label className='font-[500] text-[14px] leading-[18px] pb-[10px] text-[#656971] text-opacity-90 w-[fit-content]'>
            Button color
          </label>
          <div
            onClick={() => WidgetConfigStore.set_not_show_widget(false)}
            className='rounded-[4px] border-gray-300 bg-white border-[1px] border-b-[2px] flex  items-center justify-center h-[40px] font-medium p-3'
          >
            <div className='relative rounded-full   bg-btnColor w-[16px] h-[16px] shrink-0 border  border-gray-300 mr-[8px]'>
              <input
                onChange={handleButtonColorInputChange}
                type='color'
                value={WidgetConfigStore.config.value?.colors.btn_color}
                className='opacity-0 rounded-full w-full h-full bg-transparent absolute top-0 right-0 left-0 bottom-0'
              />
            </div>
            <input
              type='text'
              onChange={handleButtonColorInputChange}
              value={WidgetConfigStore.config.value?.colors.btn_color}
              className='w-full h-full outline-none uppercase  border-0  bg-transparent font-[500] text-[14px]'
            />
          </div>
        </div>
      </div>
      <div className='flex mt-[28px] w-full justify-end space-x-4'>
        <Button
          type='button'
          text='Cancel'
          size='sm'
          disabled={sync}
          onClick={handleCancel}
          variant='outline'
        />
        <Button
          type='button'
          disabled={sync}
          onClick={handlePublish}
          loading={WidgetConfigStore.config.style?.loading}
          text={
            WidgetConfigStore.config.style?.loading
              ? 'Saving'
              : 'Save and set live'
          }
          loadingText='Saving'
          size='sm'
          className='bg-[#1068EF]'
        />
      </div>
    </div>
  );
};
export default observer(Style);
