import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { ConStore } from '@/state/ConversationStore';
import { AuthStore } from '@/state/AuthenticationStore';
import Spinner from '@/components/atoms/Spinner';
import { useChatConversation } from '@/hooks/useChatConversation';
import MessageBubble from '@/components/molecules/MessageBubble';
import { Message } from '@/types/message.type';
import MessageInput from '@/components/molecules/MessageInput';
import TypingStatus from '@/components/molecules/TypingStatus';
import { observer } from 'mobx-react-lite';
import { User } from '@/types/user.types';
import { getInitialsFromName } from '@/helpers/index';
import { MESSAGE_TYPE } from '@/constants';

const ChatArea = ({ navigation }: any) => {
  const { send_read_receipts, get_messages, get_user_info } =
    useChatConversation();
  const [current_conversation_messages, set_current_conversation_messages] =
    useState<Map<string, Message> | null | undefined>(null);
  const [finalList, setFinalList] = useState<Message[]>([]);
  const [current_user_info, set_current_info] = useState<User | null>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  const sort = (x: Message, y: Message) => {
    return new Date(x?.createdAt).valueOf() - new Date(y?.createdAt).valueOf();
  };

  useEffect(() => {
    if (
      ConStore.selected_conversation_id &&
      ConStore.cons.has(ConStore.selected_conversation_id)
    ) {
      const con = ConStore.cons.get(ConStore.selected_conversation_id);

      if (!con?.fetched_messages && con?.messages) {
        get_messages(ConStore.selected_conversation_id);
      }

      set_current_conversation_messages(con?.messages);
      ConStore.setReRenderMessages(true);
    }
    scrollToBottom();
  }, [ConStore.selected_conversation_id, ConStore.selected_conversation]);

  useEffect(() => {
    if (
      ConStore.selected_conversation_id &&
      ConStore.cons.has(ConStore.selected_conversation_id)
    ) {
      const con = ConStore.cons.get(ConStore.selected_conversation_id);
      if (con?.fetched_messages) {
        set_current_conversation_messages(con.messages);
        ConStore.setReRenderMessages(true);
      }

      scrollToBottom();
    }
  }, [ConStore.cons, ConStore.selected_conversation_id]);

  useEffect(() => {
    if (
      ConStore.selected_conversation_id &&
      ConStore.cons.has(ConStore.selected_conversation_id) &&
      ConStore.reRenderMessages
    ) {
      const con = ConStore.cons.get(ConStore.selected_conversation_id);
      setFinalList(Array.from(con?.messages?.values() ?? []));
      ConStore.setReRenderMessages(false);
    }
  }, [ConStore.reRenderMessages, ConStore.selected_conversation_id]);

  useEffect(() => {
    scrollToBottom();
    //TODO:invoke function to send read receipts here
    if (ConStore?.selected_conversation_id) {
      send_read_receipts(ConStore?.selected_conversation_id);
    }
  }, [finalList]);

  useEffect(() => {
    const current_conversation = ConStore.cons.get(
      ConStore.selected_conversation_id as string,
    );
    if (current_conversation?.lead) {
      const current_users_info = AuthStore.users_info.get(
        (current_conversation.lead as User)._id,
      );
      if (current_users_info) {
        set_current_info(current_users_info);
      }
    }
  }, [ConStore.selected_conversation_id, AuthStore.users_info]);

  return (
    <div className='w-full h-full flex flex-col  md:p-[24px]'>
      <div className='w-full h-screen min-h-0 flex flex-col overflow-hidden bg-white md:rounded-2xl pt-3 md:pt-0 md:pb-0'>
        <div className='md:hidden fixed top-12 z-10  w-full flex  justify-between items-center bg-white  border border-x-0 border-t-0 border-border p-4'>
          <div
            className='flex items-center cursor-pointer text-secondary space-x-2'
            onClick={() => {
              navigation.goBack(0);
              ConStore.set_selected_conversation_id(null);
            }}
          >
            <AiOutlineArrowLeft className='w-5 h-5' />
            <div className='rounded-full flex flex-row justify-center items-center w-10 h-10   bg-[#24225B]'>
              <p className='text-xs font-medium text-white'>
                {getInitialsFromName(current_user_info?.user_name as string)}
              </p>
            </div>
            <p className='text-black text-md'>{current_user_info?.user_name}</p>
          </div>
          <div
            className='cursor-pointer text-secondary text-[10px]'
            onClick={() => navigation.next(2)}
          >
            Conversation details
          </div>
        </div>

        <div className='flex flex-col  flex-1 w-full h-full overflow-y-auto pb-24 pt-32 md:pb-0 md:p-[24px]  scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded min-h-0'>
          {ConStore.cons.get(ConStore.selected_conversation_id as string)
            ?.fetching_messages ||
          !current_conversation_messages ||
          ConStore.cons.get(ConStore.selected_conversation_id as string)
            ?.updating_convo ? (
            <div className='flex justify-center items-center h-full w-full'>
              <Spinner size={40} color='#033EB5' />
            </div>
          ) : (
            finalList.sort(sort).map((item, index) => {
              const isLastBubble = finalList.length - 1 === index;
              let isSameSender = false;
              if (index !== 0) {
                isSameSender =
                  (finalList[index - 1]?.sender as User)?._id ===
                  (item?.sender as User)?._id;
              }
              if (item?.is_deleted) return null;
              return (
                <MessageBubble
                  key={index}
                  messageData={item}
                  isLastBubble={isLastBubble}
                  isSameSender={isSameSender}
                />
              );
            })
          )}
          <div ref={bottomRef} />
          {/*eslint-disable-next-line no-prototype-builtins*/}
        </div>
        {ConStore.conversation_typing_status?.hasOwnProperty.call(
          ConStore.conversation_typing_status,
          ConStore.selected_conversation_id
            ? ConStore.selected_conversation_id
            : '',
        ) && (
          <div className=' w-full ml-3 flex flex-col'>
            {Object.entries(
              ConStore.conversation_typing_status[
                ConStore.selected_conversation_id
                  ? ConStore.selected_conversation_id
                  : ''
              ],
            ).map(([key, value]: any) => {
              if (key === AuthStore.currentUser?._id) return;
              if (value.status && AuthStore.users_info.has(key)) {
                ConStore.setReRenderConversationList(true);
                return (
                  <TypingStatus
                    data={AuthStore.users_info.get(key)}
                    key={key + value}
                  />
                );
              } else {
                // get user info here
                if (!AuthStore.users_info.has(key)) get_user_info(key);
              }
            })}
          </div>
        )}
        <div className='md:block w-full hidden'>
          {finalList[finalList.length - 1]?.type ===
          MESSAGE_TYPE.UNSUBSCRIBE ? (
            <div className='flex items-center bg-yellow-50 p-5 justify-center'>
              <p className='text-[12px] 2xl:text-[13px]  3xl:text-[18px]  text-black'>
                You can no longer send messages to this user
              </p>
            </div>
          ) : (
            <MessageInput lastMessages={finalList.slice(0, 11)} />
          )}
        </div>
        <div className='fixed -bottom-0 w-full md:hidden'>
          {finalList[finalList.length - 1]?.type ===
          MESSAGE_TYPE.UNSUBSCRIBE ? (
            <div className='flex items-center bg-yellow-50 p-5 justify-center'>
              <p className='text-[12px] 2xl:text-[13px]  3xl:text-[18px]  text-black'>
                You can no longer send messages to this user
              </p>
            </div>
          ) : (
            <MessageInput lastMessages={finalList.slice(0, 11)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ChatArea);
