import Search from '@/components/atoms/Search';
import { useTeammate } from '@/hooks/useTeammate';
import { AuthStore } from '@/state/AuthenticationStore';
import { teamMateStore } from '@/state/TeammateStore';
// import { DownloadIcon } from '@heroicons/react/outline';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import FilterMenu from '../Components/FilterMenu';
import Button from '@/components/atoms/Button';
import { TeamMate } from '@/types/teammate.types';
import InvitesTable from '../Components/InvitesTable';
import TeammatesTable from '../Components/TeammatesTable';

const Teammates = () => {
  const [toggle, setToggle] = useState(1);
  const [search, setSearch] = useState('');
  const [filteredTeamMates, setFilteredTeamMates] = useState<TeamMate[]>([]);
  const {
    getTeamMates,
    getInvites,
    resendInvite,
    deleteInvite,
    deleteTeamMate,
  } = useTeammate();
  const fetchingInvites = teamMateStore.fetchingInvites;
  const fetchingTeamMates = teamMateStore.fetchingTeamMates;
  const invitations = teamMateStore.invites;
  const team_mates = teamMateStore.teamMates;

  const navigate = useNavigate();

  const handleSubmit = (e: any, search: string) => {
    e.preventDefault();
    setSearch(search); // remove search from the input
  };

  const handleSearch = (e: any) => {
    const search = e.target.value;
    setSearch(search);
    if (search.trim() === '') {
      setFilteredTeamMates(team_mates);
    } else {
      const filtered = team_mates?.filter(
        (mate: any) =>
          mate?.user?.user_name
            ?.toLowerCase()
            ?.includes(search?.toLowerCase()) ||
          mate?.user?.email?.toLowerCase()?.includes(search?.toLowerCase()),
      );
      setFilteredTeamMates(filtered);
    }
  };

  const toggleTab = (index: number) => {
    setToggle(index);
  };
  const workspace_name =
    AuthStore.user_workspace_info?.active_workspace?.workspace?.company_name;

  useEffect(() => {
    getTeamMates();
    getInvites();

    if (team_mates?.length) {
      setFilteredTeamMates(team_mates);
    }
  }, [team_mates.length]);

  return (
    <div className='flex flex-col w-full  h-full overflow-hidden bg-white'>
      <div className='pt-5 sticky-top  z-[99999] px-5'>
        <div className='flex flex-row justify-between'>
          <div className='flex flex-row items-center'>
            <h1 className='text-[24px] leading-[36px] font-[500]'>Teammates</h1>
            <span className='text-[24px] leading-[36px] font-[500] text-gray-400 mx-1'>
              for
            </span>
            <h1 className='text-[24px] leading-[36px]  font-[500] text-gray-400'>
              {workspace_name}
            </h1>
          </div>
          <div>
            <Button
              text='Add new teammates'
              onClick={() => navigate('invites')}
            />
          </div>
        </div>
        <div className={`flex justify-between items-center mt-8 h-10`}>
          <div className='flex space-x-6'>
            <span
              className={`text-[14px] font-medium text-center transition duration-500 ease-in-out cursor-pointer ${
                toggle === 1
                  ? 'border-b-[2px] border-secondary text-secondary'
                  : 'text-gray-800 hover:text-secondary'
              }`}
              onClick={() => toggleTab(1)}
            >
              Teammates ({team_mates?.length})
            </span>
            <span
              className={`text-[14px] font-medium text-center transition duration-500 ease-in-out cursor-pointer ${
                toggle === 2
                  ? 'border-b-[2px] border-secondary text-secondary'
                  : 'text-gray-800 hover:text-secondary'
              }`}
              onClick={() => toggleTab(2)}
            >
              Invites sent ({invitations?.length})
            </span>
          </div>
          {toggle === 1 && (
            <div className='flex flex-row items-center'>
              <div>
                <Search
                  value={search}
                  showCommand={false}
                  transparent={true}
                  placeholder='Search teammates'
                  type='search'
                  onChange={handleSearch}
                  onKeyDown={e => {
                    if (e.code === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      if (search.trim() !== '') {
                        handleSubmit(e, search);
                      }
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='overflow-hidden h-full pt-[24px]'>
        {toggle === 1 && (
          <TeammatesTable
            team_mates={filteredTeamMates}
            loading={fetchingTeamMates}
            deleteTeammate={deleteTeamMate}
          />
        )}
        {toggle === 2 && (
          <InvitesTable
            invitations={invitations}
            loading={fetchingInvites}
            handleResendVerificationEmail={resendInvite}
            deleteInvite={deleteInvite}
          />
        )}
      </div>
    </div>
  );
};
export default observer(Teammates);
