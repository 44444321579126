import Spinner from '@/components/atoms/Spinner';
import { Icon } from '@iconify/react';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface DataTableProps<TData> {
  data: TData[];
  columns: ColumnDef<TData, string>[];
  rowCount?: number;
  pageSize?: number;
  loading?: boolean;
  pagination?: PaginationState;
  headerClassName?: string;
  showPagination?: boolean;
  noDataComponent?: ReactNode;
  rowSelection?: RowSelectionState;
  columnVisibility?: VisibilityState;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
}

export function usePagination(initialPageSize = 10) {
  const [searchParams] = useSearchParams();
  const pageIndex = Number(searchParams.get('page')) || 0;
  const pageSize = Number(searchParams.get('limit')) || initialPageSize;

  const pagination = {
    pageIndex,
    pageSize,
  };

  return {
    pagination,
    limit: pageSize,
    page: pageIndex,
  };
}

export function useSorting() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const onSortingChange = useCallback(setSorting, [setSorting]);

  return {
    sorting,
    onSortingChange,
  };
}

export const DataTable = <TData,>({
  data = [],
  pageSize = 10,
  columns,
  loading,
  sorting,
  rowCount,
  pagination,
  rowSelection,
  showPagination,
  noDataComponent,
  headerClassName,
  columnVisibility,
  onRowSelectionChange,
  onColumnVisibilityChange,
  onSortingChange,
}: PropsWithChildren<DataTableProps<TData>>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialPagination = {
    pageIndex: Number(searchParams.get('page')) || 0,
    pageSize: Number(searchParams.get('limit')) || pageSize,
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination: initialPagination,
      sorting,
      rowSelection,
      columnVisibility,
    },
    initialState: {
      pagination,
      columnVisibility,
    },
    rowCount,
    enableRowSelection: true,
    manualPagination: true,
    onPaginationChange: useCallback(
      (updater: any) => {
        const newPagination =
          typeof updater === 'function'
            ? updater(table.getState().pagination)
            : updater;

        setSearchParams(
          prev => {
            const newParams = new URLSearchParams(prev);
            newParams.set('page', String(newPagination.pageIndex));
            newParams.set('limit', String(newPagination.pageSize));
            return newParams;
          },
          { replace: true },
        );
      },
      [setSearchParams],
    ),
    onRowSelectionChange,
    onColumnVisibilityChange,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <section className='bg-white max-h-[calc(100vh-100px)] overflow-y-auto rounded-b-xl relative h-full'>
      {loading && (
        <div className='flex justify-center bg-white absolute inset-0 z-30'>
          <Spinner color='blue' size={30} />
        </div>
      )}
      {data.length > 0 ? (
        <div className='overflow-x-auto h-[calc(100%-73px)]'>
          <div className='w-full min-w-max h-full'>
            <div className='w-full overflow-auto h-full'>
              <table className='w-full caption-bottom text-md'>
                <thead className='sticky -top-[1px] w-full'>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} className='tr border-x-0'>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} className={`th ${headerClassName}`}>
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none flex items-center gap-2 justify-between'
                                  : '',
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {
                                {
                                  asc: (
                                    <Icon
                                      icon='fa-solid:sort-up'
                                      className='w-3 h-3'
                                    />
                                  ),
                                  desc: (
                                    <Icon
                                      icon='fa-solid:sort-down'
                                      className='w-3 h-3'
                                    />
                                  ),
                                }[header.column.getIsSorted() as string]
                              }
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className='tr border-0'>
                      {row.getVisibleCells().map((cell, index, array) => (
                        <td
                          key={cell.id}
                          className={`td ${index === 0 ? 'border-l-0' : ''} ${
                            index === array.length - 1 ? 'border-r-0' : ''
                          }`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        noDataComponent
      )}
      {showPagination && data.length > 0 && (
        <div className='pb-5 px-4'>
          <div className='h-6' />
          <nav className='flex items-center justify-end w-full text-md text-black/50 px-4 gap-5'>
            <span>Records per page:</span>
            <select
              className='bg-white text-black/50 outline-none border border-border rounded-md p-1'
              value={table.getState().pagination.pageSize}
              onChange={e => table.setPageSize(Number(e.target.value))}
            >
              {[5, 10, 15, 20, 25, 30].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>

            <span>
              Page {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()} ({rowCount} total records)
            </span>

            <div
              className='flex items-center gap-2'
              aria-label='Pagination Navigation'
            >
              <button
                type='button'
                onClick={() => table.firstPage()}
                disabled={!table.getCanPreviousPage()}
                className='text-black/70 disabled:text-gray-500'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  aria-hidden='true'
                  role='presentation'
                  fill='currentColor'
                >
                  <path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z'></path>
                  <path fill='none' d='M24 24H0V0h24v24z'></path>
                </svg>
              </button>
              <button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                type='button'
                className='text-black/70 disabled:text-gray-500'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  aria-hidden='true'
                  role='presentation'
                  fill='currentColor'
                >
                  <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path>
                  <path d='M0 0h24v24H0z' fill='none'></path>
                </svg>
              </button>

              <button
                type='button'
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                className='text-black/70 disabled:text-gray-500'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  aria-hidden='true'
                  role='presentation'
                  fill='currentColor'
                >
                  <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path>
                  <path d='M0 0h24v24H0z' fill='none'></path>
                </svg>
              </button>

              <button
                type='button'
                onClick={() => table.lastPage()}
                disabled={!table.getCanNextPage()}
                className='text-black/70 disabled:text-gray-500'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  aria-hidden='true'
                  role='presentation'
                  fill='currentColor'
                >
                  <path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z'></path>
                  <path fill='none' d='M0 0h24v24H0V0z'></path>
                </svg>
              </button>
            </div>
          </nav>
        </div>
      )}
    </section>
  );
};
