import Logo from '@/components/atoms/Logo';
import SignUpForm from '@/components/template/forms/SignUpForm';
import SignUpWithInviteForm from '@/components/template/forms/SignUpWithInviteForm';
import { useAuth } from '@/hooks/useAuth';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';

const SignUp = () => {
  const { register, registerWithInvite } = useAuth();
  const location = useLocation();
  const fromUrl = location.state?.from?.pathname;
  const isInvite =
    fromUrl?.includes('teams/join') ||
    location.state?.from?.search?.includes('valid=true');

  return (
    <div className='flex justify-center items-start bg-white overflow-auto h-[100vh]'>
      <div className='w-full max-w-[500px] px-5 py-10'>
        <div className='flex flex-col items-center justify-center mb-6'>
          <Link to={'/'} className='flex flex-col items-center justify-center'>
            <Logo size='lg' />
            <h5>
              <span className='font-bold text-primary'>Spark</span>
              Hub
            </h5>
          </Link>
          <p className='text-center w-[70%] mt-5'>
            Create an account to get started.
          </p>
        </div>
        <div>
          {/* <p className='text-center text-md text-gray-600 mb-5'>Sign up with</p> */}

          {/* <div className='mt-1 flex justify-center gap-3 items-center'>
            <div>
              <button
                onClick={() => facebookAuth()}
                className='w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium hover:bg-gray-50'
              >
                <span className='sr-only'>Sign up with Facebook</span>
                <FaFacebookF size={20} className='text-blue' />
              </button>
            </div>
            <div>
              <button
                onClick={() =>
                  googleAuth({
                    state: 'signup',
                  })
                }
                className='w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
              >
                <span className='sr-only'>Sign up with Google</span>
                <FcGoogle size={20} />
              </button>
            </div>
          </div> */}
          {/* <div className='mt-6 relative'>
            <div
              className='absolute inset-0 flex items-center'
              aria-hidden='true'
            >
              <div className='w-full border-t border-gray-300'></div>
            </div>
            <div className='relative flex justify-center text-md'>
              <span className='px-2 bg-white text-gray-500'>
                Or continue with
              </span>
            </div>
          </div> */}
        </div>
        {isInvite ? (
          <SignUpWithInviteForm handleSignUp={registerWithInvite} />
        ) : (
          <SignUpForm handleSignUp={register} />
        )}
      </div>
    </div>
  );
};

export default observer(SignUp);
