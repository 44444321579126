import React, { FC } from 'react';
import ToolTip from '@/components/atoms/Tooltip';

interface PlaceholderProps {
  height?: number;
  fTitle?: string;
  sTitle?: string;
}

const Placeholder: FC<PlaceholderProps> = ({ height, fTitle, sTitle }) => {
  return (
    <div>
      <div
        className={`w-full h-${height} bg-gray-100 p-[2px]  rounded-[8px] flex-1`}
      >
        <div className='flex items-center w-full'>
          <div className='flex flex-1 flex-nowrap'>
            <span
              className={`bg-white bg-gray-100 shadow border border-black border-opacity-20 cursor-pointer flex items-center justify-center text-center p-2 rounded-[7px] w-[100%]`}
            >
              <p className='text-[12px] font-medium leading-4 flex-none'>
                {fTitle}
              </p>
            </span>

            {sTitle ? (
              <span
                className={`cursor-pointer flex items-center justify-center text-center p-2 rounded-[7px] w-[100%]`}
              >
                <ToolTip title='Coming soon'>
                  <p className='text-[12px] font-medium leading-4 flex-none'>
                    {sTitle}
                  </p>
                </ToolTip>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Placeholder;
