import { FacebookProvider } from '@kazion/react-facebook-login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ErrorFallback } from './components/organisms/ErrorFallback';
import './index.css';

const SENTRY_DNS = import.meta.env.VITE_SENTRY_DNS;

if (SENTRY_DNS) {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_NODE_ENV,
    enabled: import.meta.env.VITE_NODE_ENV !== 'development',
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <FacebookProvider
        appId={import.meta.env.VITE_FACEBOOK_APP_ID}
        version='v10.0'
      >
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </FacebookProvider>
    </ErrorBoundary>
    <Toaster
      toastOptions={{
        duration: 5000,
      }}
      position='top-right'
      containerClassName='z-[10000000!important]'
    />
  </React.StrictMode>,
);
