import DateDropdown from '@/components/molecules/DateDropdown';
import { extractDomain } from '@/helpers/index';
import {
  UpdateCustomFieldPayload,
  useCustomField,
} from '@/hooks/useCustomField';
import { customFieldStore } from '@/state/CustomFieldStore';
import { User } from '@/types/user.types';
import { Icon } from '@iconify/react';
import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';

type EditDetailsProps = {
  data: User;
  handlePhoneChange: (value: any) => void;
  userData: any;
};

const EditDetails = ({
  data,
  userData,
  handlePhoneChange,
}: EditDetailsProps) => {
  const { customFields, customFieldsObject, setCustomFieldsObject } =
    customFieldStore;
  const {
    updateContactWithCustomField,
    getContactCustomFields,
    getCustomFields,
  } = useCustomField();

  // map the user details on custom field values
  useEffect(() => {
    getCustomFields();
    getContactCustomFields(data._id as string);
  }, [data]);

  const setFocusedInput = (inputRef: HTMLInputElement | null) => {
    userData.focusedInputRef.current = inputRef;
  };

  const onInputFocus = (inputRef: HTMLInputElement | null) => {
    setFocusedInput(inputRef);
  };

  const updateCustomField = (fieldId: string, value: string | null) => {
    const update: UpdateCustomFieldPayload = {
      contactId: data._id as string,
      customFieldId: fieldId,
      value: value,
    };

    updateContactWithCustomField(update);
  };

  const onInputBlur = () => {
    setFocusedInput(null);
    userData.handleUpdateDetails();
  };

  const handleKeyPress = (event: any) => {
    if (!userData.buttonDisabled) {
      if (event.key === 'Enter') {
        userData.handleUpdateDetails();
        event.target.blur();
      }
    }
  };

  return (
    <div
      className='transition-[height] w-full mt-2'
      id='SparkHubSaveEditedDetailsForContact'
    >
      {/* Name */}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>Name</p>
        <input
          onChange={e => userData.set_user_name(e.target.value)}
          onKeyDown={handleKeyPress}
          value={userData.user_name ?? ''}
          type='text'
          placeholder='Add name'
          className={`${
            data?.user_name === null || data?.user_name === ''
              ? ''
              : 'capitalize'
          } flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] border-2 border-transparent focus:border-secondary hover:border-border/30 text-black cursor-pointer`}
        />
      </div>

      {/* Email */}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>Email</p>
        <input
          onChange={e => userData.set_email(e.target.value)}
          onKeyDown={handleKeyPress}
          value={userData.email ?? ''}
          type='text'
          placeholder='Add email'
          className={`flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
        />
      </div>

      {/* Phone */}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>Phone</p>
        <div>
          <div
            className={`w-full hover:border-border/30 rounded-[4px] border-2 border-transparent ${
              (!userData.isValid && userData.phoneFocus) ||
              !userData.isNumAvailable
                ? 'border-red-500'
                : 'border-secondary'
            } !text-black cursor-pointer`}
          >
            <PhoneInput
              onChange={handlePhoneChange}
              onKeyDown={handleKeyPress}
              onFocus={() => userData.setPhoneFocus(true)}
              onBlur={() => userData.setPhoneFocus(false)}
              value={userData.phone_number ?? ''}
              placeholder='Add phone'
              containerClass='!flex !w-full !h-[20px]' // To manage the container layout
              inputClass={`!ml-[40px] !flex-1 !h-[20px] !duration-100 !ease-in-out !w-full !px-1 !outline-none !text-md !focus:text-[12px] !rounded-[4px] !hover:border-border/30 !border-2 !border-transparent`}
              buttonClass='!border-r-2 !border-transparent' // Optional, for the flag button styling
              dropdownClass='!bg-white !shadow-md !border !border-gray-300 !rounded-md !max-h-60 !overflow-auto'
            />
          </div>
          {!userData.isValid && userData.phoneFocus && (
            <p className='text-[10px] text-red-500'>
              Please enter a valid phone number
            </p>
          )}
          {!userData.isNumAvailable && (
            <p className='text-[10px] text-red-500'>
              Phone number already in use
            </p>
          )}
        </div>
      </div>

      {/* Status */}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>
          Status
        </p>
        <select
          value={userData.status ?? ''}
          placeholder='Select status'
          onChange={e => userData.set_status(e.target.value)}
          onFocus={() => onInputFocus}
          onBlur={onInputBlur}
          ref={userData.focusedSelectInputRef}
          onKeyDown={handleKeyPress}
          className={`flex-1 duration-100 ease-in-out w-full  outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
        >
          <option value='active'>Active</option>
          <option value='inactive'>Inactive</option>
        </select>
      </div>

      {/* Country */}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>
          Country
        </p>
        <input
          onChange={e => userData.set_country(e.target.value)}
          onKeyDown={handleKeyPress}
          value={userData.country ?? ''}
          type='text'
          placeholder='Add country'
          className={`flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
        />
      </div>

      {/* City */}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>City</p>
        <input
          onChange={e => userData.set_city(e.target.value)}
          onKeyDown={handleKeyPress}
          value={userData.city ?? ''}
          type='text'
          placeholder='Add City'
          className={`flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
        />
      </div>

      {/* Company Name */}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>
          Company
        </p>
        <input
          onChange={e => userData.set_company_name(e.target.value)}
          onKeyDown={handleKeyPress}
          value={userData.company_name ?? ''}
          type='text'
          placeholder='Add company name'
          className={`${
            data?.company_name === null || data?.company_name === ''
              ? ''
              : 'capitalize'
          } flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
        />
      </div>

      {/* Company Size */}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>Size</p>
        <input
          onChange={e => userData.set_company_size(e.target.value)}
          onKeyDown={handleKeyPress}
          value={userData.company_size ?? ''}
          type='text'
          placeholder='Add company size'
          className={`flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
        />
      </div>

      {/* Company Website*/}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>
          Website
        </p>
        <input
          onChange={e => userData.set_company_website(e.target.value)}
          onKeyDown={handleKeyPress}
          value={userData.company_website ?? ''}
          type='text'
          placeholder='Add company website'
          className={`${
            data?.company_website === null || data?.company_website === ''
              ? ''
              : 'text-secondary'
          } flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
        />
        {data?.company_website !== null && data?.company_website !== '' && (
          <a
            href={`https://${extractDomain(data?.company_website)}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Icon
              icon='solar:link-bold'
              className='text-secondary text-[18px] ml-2'
            />
          </a>
        )}
      </div>

      {/* Company Industry*/}
      <div className='flex items-center mb-4'>
        <p className='mr-2 basis-24 text-md font-medium text-gray-500'>
          Industry
        </p>
        <input
          onChange={e => userData.set_company_industry(e.target.value)}
          onKeyDown={handleKeyPress}
          value={userData.company_industry ?? ''}
          type='text'
          placeholder='Add company industry'
          className={`${
            data?.company_industry === null || data?.company_industry === ''
              ? ''
              : 'capitalize'
          } flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
        />
      </div>

      {customFields.map(customField => {
        const className = clsx(
          customFieldsObject[customField._id] === null ||
            customFieldsObject[customField._id] === ''
            ? customField.type === 'date' || customField.type === 'number'
              ? 'w-full'
              : ''
            : 'w-full',
          customField.type == 'checkbox'
            ? 'ml-1'
            : 'w-full px-1 hover:border-border/30 ',
          `flex-1 duration-100 ease-in-out w-full  outline-none  text-md  focus:text-[12px] rounded-[4px]  border-2 border-transparent focus:border-secondary text-black cursor-pointer`,
        );
        return (
          <div className='flex items-center mb-4' key={customField._id}>
            <p className='mr-2 basis-24 text-md font-medium text-gray-500 whitespace-nowrap flex-none'>
              {customField.field}
            </p>
            {customField.type === 'select' ? (
              <select
                value={customFieldsObject[customField._id] ?? ''}
                placeholder='Select status'
                onChange={e => {
                  const { value } = e.target;
                  customFieldStore.setCustomFieldsObject(
                    customField._id,
                    value,
                  );
                  updateCustomField(customField._id, value);
                }}
                onFocus={() => onInputFocus}
                onBlur={() =>
                  updateCustomField(
                    customField?._id,
                    customFieldsObject[customField._id],
                  )
                }
                ref={userData.focusedSelectInputRef}
                onKeyDown={handleKeyPress}
                className={`flex-1 duration-100 ease-in-out w-full px-1 outline-none  text-md  focus:text-[12px] rounded-[4px] hover:border-border/30  border-2 border-transparent focus:border-secondary text-black cursor-pointer`}
              >
                <option value='' disabled hidden>
                  select
                </option>
                {customField.metaData.map((option: any) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : customField.type === 'date' ? (
              <div className='flex-1'>
                <DateDropdown
                  currentDate={customFieldsObject[customField._id]}
                  currentDay={parseInt(
                    customFieldsObject[customField._id]
                      ?.toString()
                      ?.split('-')[0],
                  )}
                  onSelect={date => {
                    setCustomFieldsObject(customField._id, date);
                    updateCustomField(
                      customField._id,
                      customFieldsObject[customField._id],
                    );
                  }}
                />
              </div>
            ) : (
              <input
                onChange={e => {
                  const { checked, value } = e.target;
                  if (customField.type === 'checkbox') {
                    customFieldStore.setCustomFieldsObject(
                      customField._id,
                      checked,
                    );
                    updateCustomField(
                      customField._id,
                      customFieldsObject[customField._id],
                    );
                  } else {
                    setCustomFieldsObject(customField._id, value);
                  }
                }}
                onFocus={() => onInputFocus}
                onBlur={() =>
                  updateCustomField(
                    customField._id,
                    customFieldsObject[customField._id],
                  )
                }
                ref={userData.focusedInputRef}
                value={customFieldsObject[customField._id] ?? ''}
                checked={
                  (customFieldsObject[customField._id] ||
                    customFieldsObject[customField._id] === 'true') &&
                  customFieldsObject[customField._id] !== 'false'
                    ? true
                    : false
                }
                type={customField.type}
                placeholder='Add'
                className={className}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
export default observer(EditDetails);
