import { useTeammate } from '@/hooks/useTeammate';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { CheckIcon } from '@heroicons/react/solid';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

const data = [
  { id: 1, name: 'Active' },
  { id: 2, name: 'Away' },
];

const TableMenu = ({ user, status }: { user: any; status: string }) => {
  const [selected, setSelected] = useState(
    data.find(s => s.name === status) || data[0],
  );
  const { teammateStatus } = useTeammate();

  const handleTeammateStatus = (selectedStatus: (typeof data)[number]) => {
    setSelected(selectedStatus);
    teammateStatus({
      userId: user._id,
      status: selectedStatus.name,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='bg-white pl-3 py-2 text-left cursor-pointer focus:outline-none sm:text-sm flex items-center space-x-4'>
        <AiFillCaretDown className='h-3 w-3 text-gray-800' aria-hidden='true' />
        <span className='text-[14px] block truncate text-gray-800'>
          {selected.name}
        </span>
        <span
          className={`${
            selected.name === 'Active' ? 'bg-success/90 ' : 'bg-warning'
          } rounded-full h-2 w-2`}
        />
      </DropdownMenuTrigger>

      <DropdownMenuContent className='w-30 gap-y-2 ml-10'>
        {data.map(item => (
          <DropdownMenuItem
            key={item.id}
            onSelect={() => handleTeammateStatus(item)}
            className='flex items-center justify-between cursor-pointer text-md'
          >
            <span
              className={`${
                selected.name === item.name ? 'text-secondary' : ''
              } text-md`}
            >
              {item.name}
            </span>
            {selected.name === item.name && (
              <CheckIcon
                className='h-5 w-5 text-secondary font-bold'
                aria-hidden='true'
              />
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default observer(TableMenu);
