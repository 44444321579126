import Spinner from '@/components/atoms/Spinner';
import ConversationListItem from '@/components/molecules/ConversationListItem';
import { CONVERSATION_SORT } from '@/constants/index';
import { useChatConversation } from '@/hooks/useChatConversation';
import { ConStore } from '@/state/ConversationStore';
import { Conversation, CONVERSATION_STATUS } from '@/types/conversation.types';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import { RiInboxArchiveFill } from 'react-icons/ri';
import { useInView } from 'react-intersection-observer';
import ConversationListHeader from '../ConversationListHeader';

const sortData = [{ name: 'Latest' }, { name: 'Oldest' }];
const filterData = [
  {
    name: 'Open',
    status: CONVERSATION_STATUS.OPEN,
    icon: (
      <svg
        className='w-5 h-5  pointer-events-none group-hover:text-primary-light'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z'
        />
      </svg>
    ),
    count: 2,
  },
  {
    name: 'Archived',
    status: CONVERSATION_STATUS.CLOSED,
    icon: (
      <RiInboxArchiveFill className='w-5 h-5  pointer-events-none group-hover:text-primary-light' />
    ),
    count: 4,
  },
];
const ConversationList: FC<any> = props => {
  const [sortedBy, setSortedBy] = useState(sortData[0]);
  const [filterState, setFilterState] = useState(filterData[0]);
  const conversationListRef = useRef<HTMLDivElement>(null);
  const { ref: lastElementRef, inView } = useInView({
    threshold: 1,
    delay: 100,
  });
  const { getConversations, getMoreConversations } = useChatConversation();

  const sort = (x: Conversation, y: Conversation) => {
    if (
      ConStore.sorted_by === CONVERSATION_SORT.LATEST &&
      y.last_message &&
      x.last_message
    ) {
      return (
        new Date(y.last_message.createdAt).valueOf() -
        new Date(x.last_message.createdAt).valueOf()
      );
    } else if (
      ConStore.sorted_by === CONVERSATION_SORT.OLDEST &&
      y.last_message &&
      x.last_message
    ) {
      return (
        new Date(x.last_message.createdAt).valueOf() -
        new Date(y.last_message.createdAt).valueOf()
      );
    } else {
      return 0;
    }
  };

  useEffect(() => {
    getConversations({
      page: 1,
      status: filterState.status,
      sort: ConStore.sorted_by === CONVERSATION_SORT.LATEST ? -1 : 1,
      channel: props.conversationChannel,
    });
    conversationListRef.current?.scrollTo(0, 0);
  }, [
    sortedBy,
    filterState,
    ConStore.search_conversations,
    props.conversationChannel,
  ]);

  useEffect(() => {
    if (
      inView &&
      ConStore.conversationMetaData?.hasMore &&
      (!ConStore.fetchingConversations || !ConStore.fetchingMoreConversations)
    ) {
      getMoreConversations({
        status: filterState.status,
        page: ConStore.conversationMetaData?.page + 1,
        sort: ConStore.sorted_by === CONVERSATION_SORT.LATEST ? -1 : 1,
        channel: props.conversationChannel,
      });
    }
  }, [inView]);

  return (
    <div
      className={`bg-[#FBFCFD] md:w-[20%] md:min-w-[220px] w-full shrink-0 flex flex-col pb-[160px] h-full`}
    >
      <ConversationListHeader
        {...props}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
        sortData={sortData}
        filterData={filterData}
        filterState={filterState}
        setFilterState={setFilterState}
        finalList={ConStore.conversations}
      />
      {ConStore.fetchingConversations ? (
        <div className='flex justify-center p-5'>
          <Spinner size={40} color='#033EB5' />
        </div>
      ) : (
        <div
          className={`${
            ConStore.search_conversations.length <= 0
              ? ' flex items-center justify-center'
              : ''
          } h-full w-full`}
        >
          {ConStore.searchTerm != '' ? (
            <div>
              {ConStore.search_conversations.length <= 0 &&
              ConStore.fetchingConversations == false ? (
                <div className='h-full flex justify-center'>
                  {ConStore.searchBoxNotEmpty && (
                    <p className='text-gray-500 text-base font-bold'>
                      No results
                    </p>
                  )}
                </div>
              ) : (
                <div
                  className='h-full w-full overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded'
                  ref={conversationListRef}
                >
                  {props?.userData?._id ? (
                    <div>
                      {ConStore.conversations
                        .filter(
                          (convo: any) =>
                            convo?.created_by?._id === props.userData._id,
                        )
                        .slice()
                        .sort(sort)
                        .map((item, index) => {
                          if (index === ConStore.conversations.length - 1) {
                            return (
                              <div ref={lastElementRef} key={item._id}>
                                <ConversationListItem data={item} {...props} />
                              </div>
                            );
                          }
                          return (
                            <ConversationListItem
                              key={item._id}
                              data={item}
                              {...props}
                            />
                          );
                        })}
                    </div>
                  ) : (
                    <div>
                      {ConStore.conversations
                        .slice()
                        .sort(sort)
                        .map((item, index) => {
                          if (index === ConStore.conversations.length - 1) {
                            return (
                              <div ref={lastElementRef} key={item._id}>
                                <ConversationListItem data={item} {...props} />
                              </div>
                            );
                          }
                          return (
                            <ConversationListItem
                              key={item._id}
                              data={item}
                              {...props}
                            />
                          );
                        })}
                    </div>
                  )}
                  <div className='h-7 w-full'>
                    {ConStore.fetchingMoreConversations && (
                      <Spinner size={20} color='#033EB5' />
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className='h-full w-full overflow-y-auto  scrollbar-thin scrollbar-thumb-gray-200 scrollbar-thumb-rounded'
              ref={conversationListRef}
            >
              {props?.userData?._id ? (
                <div>
                  {ConStore.conversations
                    .filter(
                      (convo: any) =>
                        convo?.created_by?._id === props.userData._id,
                    )
                    .slice()
                    .sort(sort)
                    .map((item, index) => {
                      if (index === ConStore.conversations.length - 1) {
                        return (
                          <div ref={lastElementRef} key={item._id}>
                            <ConversationListItem data={item} {...props} />
                          </div>
                        );
                      }
                      return (
                        <ConversationListItem
                          key={item._id}
                          data={item}
                          {...props}
                        />
                      );
                    })}
                </div>
              ) : (
                <div>
                  {ConStore.conversations
                    .slice()
                    .sort(sort)
                    .map((item, index) => {
                      if (index === ConStore.conversations.length - 1) {
                        return (
                          <div ref={lastElementRef} key={item._id}>
                            <ConversationListItem data={item} {...props} />
                          </div>
                        );
                      }
                      return (
                        <ConversationListItem
                          key={item._id}
                          data={item}
                          {...props}
                        />
                      );
                    })}
                </div>
              )}
              <div className='h-7 w-full'>
                {ConStore.fetchingMoreConversations && (
                  <Spinner size={20} color='#033EB5' />
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div></div>
    </div>
  );
};

export default observer(ConversationList);
